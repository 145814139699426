<template>
    <div class="page-eva">
        <div class="title">{{$t('detailTwo.eTitle')}}</div>
        <div class="eva-wrap" v-for="(item,idx) in evaluate" :key="idx">
            <div class="e-wrap">
                <div class="e-l">
                    <div class="e-l-imgs row">
                        <img src="../../../assets/img/perm/star.png" class="ic" v-for="(item,idx) in item.level" :key="idx">
                    </div>
                    <img :src="item.avatar" class="img" 
                     @click="toggleImgPanel(item.avatar)" 
                    />
                    <p class="txt clamp">{{ item.nickname }}</p>
                </div>
                <div class="e-r">
                    <p class="con">{{ item.title }}</p>
                    <div class="img-wrap row" :class="classObj">
                        <img :src="img" class="img" v-for="(img,idx) in splitImgStr(item.images)" :key="idx" 
                          @click="toggleImgPanel(img)"
                        />
                    </div>
                    <div class="bom-wrap row">
                        <img src="../../../assets/img/perm/zan.png" class="ic"  />
                        <span class="txt count">{{ item.goodnum }}</span>
                        <!-- <span class="txt">个人 觉得很赞</span> -->
                        <span class="txt"></span>
                        <span class="txt time">{{ $tools.date('m/d Y',item.thetime) }}</span>
                    </div>
                </div>      
            </div>
            
        </div>
        <div class="img-panel" v-if="isShowImgPanel">
            <div class="mask" @click="toggleImgPanel"></div>
            <img :src="showImg" class="img animated fadeIn">
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  import tools from '@/assets/js/tools.js'
  export default {
    data(){
        return{
            isShowImgPanel:false,
            showImg:'',
        }
    },
    props:['evaluate'],

    methods:{
        splitImgStr(imgStr){
            return imgStr ? imgStr.split(',') : [];
        },


        toggleImgPanel(img){
            this.isShowImgPanel = !this.isShowImgPanel;
            if(img){
                this.showImg = img;
            }
            const bodyEl = document.body;
            if(this.isShowImgPanel){
                bodyEl.style.overflow = 'hidden';
                bodyEl.style.height = '100%';
            }else{
                bodyEl.style.overflow = 'auto';
                bodyEl.style.height = 'auto';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
    .page-eva{
        // margin-top: 0.70rem;
        width: 100%;
        background: #fff;

        .title{
            width: 100%;
            height: 0.94rem;
            background: #253684;
            line-height: 0.94rem;
            text-align: center;
            font-size: 0.34rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
        }
        .eva-wrap{
            padding: 0 0.20rem;
        }
        .e-wrap{
            padding: 0.34rem 0 0.40rem;
            display: flex;
            align-items: flex-start;
            border-bottom: 1px solid #eee;
        }
        .e-l{
            margin-right: 0.20rem;
            .ic{
                width: 0.30rem;
                height: 0.30rem;
                margin-right: 0.03rem;
                margin-bottom: 0.08rem;
            }
            .img{
                width: 1.54rem;
                height: 1.54rem;
                object-fit: cover;
            }
            .txt{
                max-width: 1.54rem;
                text-align: center;
                line-height: 0.46rem;
                font-size: 0.24rem;
            }
        }
        .e-r{
            .con{
                font-size: 0.26rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 0.36rem;
            }
            .img-wrap{
                flex-wrap: wrap;
                margin-top: 0.13rem;
                .img{
                    width: 1.20rem;
                    height: 1.20rem;
                    margin-right: 0.13rem;
                    object-fit: cover;
                    margin-bottom: 0.13rem;
                    &:nth-child(4n){
                        margin-right: 0;
                    }
                }
                &.pc{
                    .img{
                        &:nth-child(4n){
                        margin-right: 0.13rem;
                    }
                    }

                }
                
            }
            .bom-wrap{
                .ic{
                    width: 0.28rem;
                    height: 0.28rem;
                    margin-right: 0.06rem;
                }
                .txt{
                    font-size: 0.24rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                    &.count{
                        color: #253684;
                    }
                }
                .time{
                    margin-left: auto;
                    font-size: 0.24rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #999999;
                }
            }
        }

    }
    .img-panel{
       position: fixed;
       z-index: 20;
       left: 0;
       top: 0;
       width: 100%;
       height: 100%;
       .mask{
           position: absolute;
           left: 0;
           top: 0;
           width: 100%;
           height: 100%;
           z-index: 2;
           background: rgba(0,0,0,.4);
       }
       .img{
           width: 5.00rem;
           height: auto;
           position: absolute;
           left: 50%;
           z-index: 3;
           margin-left: -2.50rem;
           top: 40%;
           transform: translateY(-50%);
       }
   }


  
</style>
