<template>
    <div class="page-tips column">
        <img src="../../assets/img/perm/success.png" class="img">
        <!-- <h1 class="title">{{$t('success.sucTitle')}}</h1> -->
        <!-- 
        <div class="tips">
            <span class="t-txt">
                {{$t('success.sucTipsT')}}
            </span>
            <div class="t-tips" v-html="tip.content">
                {{$t('success.sucTipsB')}}
            </div>
        </div> 
        -->
        <h1 class="title"> {{tip.title}}</h1>
        <div class="tips" v-html="tip.content"></div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_TIPS.JS";
  export default {
    data(){
        return{

        }
    },
    props:['tip'],
    methods:{

    }
}
</script>

<style lang="scss" scoped>
   .page-tips{
        width: 100%;
        align-items:center;
        padding-top: 0.40rem;
   }
   .img{
       width: 2.00rem;
       height: 2.00rem;
   }
   .title{
        margin-top: 0.20rem;
        font-size: 0.36rem;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
   }
   .tips{
        width: 100%;
        padding: 0 0.30rem;
        margin-top: 0.45rem;
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.36rem;
        text-align: justify;
        .t-tips{
            color:#F30C0C;
        }
    }
   // 1400px  1920px共有部分
   @media (min-width:751px){
        .page-tips{
            padding-top: 40px;
        }
        .img{
            width: 100px;
            height: 100px;
        }
        .title{
            margin-top: 10px;
            font-size: 18px;
        }
        .tips{
            padding: 15px;
            margin-top: 22px;
            font-size: 12px;
            line-height: 18px;
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
