<template>
    <div class="page-code">
        <div class="inner row">
            <img src="../../assets/img/code.png" class="c-img" @click="toggleImgPanel">
            <div class="c-tips">
                <p class="t-title">{{$t('success.cTitle')}}</p>
                <p class="t-tips">{{$t('success.cTips')}}</p>
            </div>
        </div>
        <div class="img-panel" v-if="isShowImgPanel">
            <div class="mask" @click="toggleImgPanel"></div>
            <img src="../../assets/img/code.png" class="img animated fadeIn">
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_CODE.JS";
  export default {
    data(){
        return{
           isShowImgPanel:false,
        }
    },
    methods:{
        toggleImgPanel(){
            this.isShowImgPanel = !this.isShowImgPanel;
            const bodyEl = document.body;
            if(this.isShowImgPanel){
                bodyEl.style.overflow = 'hidden';
                bodyEl.style.height = '100%';
            }else{
                bodyEl.style.overflow = 'auto';
                bodyEl.style.height = 'auto';
            }
        },
    }
}
</script>

<style lang="scss" scoped>
   .page-code{
        width: 100%;
        padding: 0.37rem 0.30rem;
   }
   .inner{
       width: 100%;
       display: flex;
    //    height: 1.16rem;
   }
   .c-img{
        width: 1.16rem;
        height: 1.16rem;
        border-radius: 0.10rem;
        margin-right: 0.18rem;
        object-fit: cover;
        cursor: pointer;
   }
   .t-title{
        margin-top: 0.02rem;
        font-size: 0.29rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
   }
   .t-tips{
        font-size: 0.22rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.36rem;
   }
   .img-panel{
       position: fixed;
       z-index: 10;
       left: 0;
       top: 0;
       width: 100%;
       height: 100%;
       .mask{
           position: absolute;
           left: 0;
           top: 0;
           width: 100%;
           height: 100%;
           z-index: 2;
           background: rgba(0,0,0,.4);
       }
       .img{
           width: 5.00rem;
           height: auto;
           position: absolute;
           left: 50%;
           z-index: 3;
           margin-left: -2.50rem;
           top: 40%;
       }
   }
   // 1400px  1920px共有部分
   @media (min-width:751px){
        .page-code{
            padding: 18px 15px 0;
        }
        .inner{
            height: 58px;
        }
        .c-img{
            height: 58px;
            width: 58px;
            border-radius: 5px;
            margin-right: 9px;
        }
        .t-title{
            margin-top: 1px;
            font-size: 15px;
        }
        .t-tips{
            font-size: 11px;
            line-height: 18px;
        }

        .img-panel{
            .img{
                width: 250px;
                margin-left: -1.25rem;
            }
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
