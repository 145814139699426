export const pageHeader = {
    sTips: '输入手机号或订单号搜索订单',
    tabHome: '首页',
    tabCat: '分类',
    tabAbout: '关于我们',
}
export const pageFooter = {
    more: '更多信息',
    about:'关于我们',
    item:'服务条款',
    privacy:'隐私政策',
    exchange:'退换政策'
}
export const home = {
    litTitle: '全部商品',
}

export const detail = {
    soldTxt: '售出',
    serviceTime: '15天鉴赏期',
    servicePay: '货到付款',
    serviceShip: '免运费',
    buyNow: '立即购买',
    detailTxt:'商品详情',
   
    combo:'套餐',
    specTitle:'选择规格',
    specAmountTxt:'合计',
 
    addrTitle:'填写信息',
    addrName:'姓名',
    addrNameP:'请输入收货人姓名',
    addrMobile:'联系电话',
    addrMobileP:'请填写电话号码',
    addrShiper:'配送方式',
    addrShiperP:'请选择配送方式',
    addrAddr:'地区',
    addrAddrP:'选择地区',
    addrAddrS:'选择地区',
    addrShopP:'选择门店',
    // addrAddrP:'请填写城市、地区',
    addrDetail:'详细地址',
    addrStoreName:'商铺名',
    addrStoreNum:'商铺号',
    addrDetailP:'请填写详细地址',
    addrEmail:'邮     箱',
    addrEmailP:'请填写邮箱',
    addrCode:'邮     编',
    addrCodeP:'请填写邮编',
    addrNote:'备     注',
    addrNoteP:'请输入备注',
    addrPay:'付款方式',
    addrPayV:'货到付款',
    addrBtn:'确认提交',

    wTitle:'买方担保',
    wTxt1:'如果你没有收到货物，全额退款',
    wTxt2:'如果您购买的产品和说明不符合全部或部分要求，则全额退款',

}
export const detailTwo = {
    // soldTxt: '售出',
    // serviceTime: '15天鑒賞期',
    // servicePay: '貨到付款',
    // serviceShip: '免運費',
    buyNow: '快速在线订购',
    // detailTxt:'商品詳情',
  
    combo:'套餐',
    // specTitle:'選擇規格',
    specAmountTxt:'合计',
  
    // addrTitle:'填寫信息',
    addrName:'姓名',
    addrNameP:'请输入收货人姓名',
    addrMobile:'手机',
    addrMobileP:'请填写手机号码',
    addrShiper:'付款方式',
    addrShiperP:'请选择付款方式',
    addrAddr:'地区',
    addrAddrP:'选择地区',
    addrAddrS:'选择地区',
    addrShopP:'选择地区',
    // addrAddrP:'請填寫城市、地區',
    addrDetail:'详细地址',
    addrStoreName:'商铺名',
    addrStoreNum:'商铺号',
    addrDetailP:'请填写详细地址',
    addrEmail:'邮     箱',
    addrEmailP:'请填写邮箱',
    addrCode:'邮     编',
    addrCodeP:'请填写邮编',
    addrNote:'备     注',
    addrNoteP:'请输入备注',
    addrPay:'付款方式',
    addrPayV:'货到付款',
    addrBtn:'确认提交',

    // wTitle:'買方擔保',
    // wTxt1:'如果妳沒有收到貨物，全額退款',
    // wTxt2:'如果您購買的産品和說明不符合全部或部分要求，則全額退款',
    eTitle: '商品评论',
    nTitle: '购物须知',
  }

export const success = {
    headerTitle:'购买成功',
    sucTitle:'订单成功',
    sucTipsT:' 恭喜您！您的订单提交成功，我们将尽快交货。感谢您的支持！小贴士：支持货到付款+免费送货+15天鉴赏期。如果您在收到商品后有任何问题，请与我们的在线服务联系。我们会尽快回复您！祝您购物愉快！',
    sucTipsB:'请记住订单号，以便以后再检查订单。',
    oTime:'日期',
    oNo:'订单号',
    oPay:'付款方式',
    oShip:'运费',
    oTotal:'共计',
    cTitle:'通过二维码添加 LINE 售后客服',
    cTips:'请在LINE应用中打开好友选项，点击右上角的“添加好友”按钮，选择“二维码”后进行扫描。'
}
export const order = {
    headerTitle:'我的订单',
    oNo:'订单号',
    oTime:'购买日期',
    oName:'姓名',
    oMobile:'联系电话',
    oShiper:'配送方式',
    oAddr:'详细地址',
    oEmail:'邮箱',
    oNote:'备注',
    oPay:'付款方式',
}








