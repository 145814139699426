<template>
    <div>
        <div class="page-header" @mousewheel.prevent="stopMove"    @touchmove.prevent="stopMove">
            <div class="inner row" >
                <div class="menu zzkicon-caidan" @click="toggleMenuPanel" ></div>
                <div class="logo">
                    <img :src="baseInfo.logo_image"  class="img">
                </div>
                <div class="tab home-tab" @click="canGoHome && navTo('/')">{{$t('pageHeader.tabHome')}}</div>
                <div class="tab cat-tab" @click="toggleMenuPanel">{{$t('pageHeader.tabCat')}}</div>
                <div class="tab about-tab" @click="canGoAbout && goAbout()">{{$t('pageHeader.tabAbout')}}</div>
                <!--
                <div class="search row">
                    <input type="text" class="inpt" :placeholder="$t('pageHeader.sTips')" v-model="tel">
                    <div class="s-btn center" @click="goOrder">
                        <span class="zzkicon-31sousuo s-img" />
                    </div>
                </div> 
                -->

                <!-- 
                <div class="lang" @click="toggleLang">
                    {{lang == 'CN' ? '简' : '繁'}}
                </div>
                 -->
            </div>
        </div>
        <div class="page-header-placer" /> 
        <div class="menu-panel" v-if="isShowMenuPanel"  @mousewheel.prevent="stopMove"    @touchmove.prevent="stopMove">
            <div class="mask" @click.stop="toggleMenuPanel" ></div>
            <div class="content" >
                <div class="m-inner row">
                    <div class="m-item clamp" v-for="item in baseInfo.cates" :key="item.id" @click="tapCat(item.id)">
                        {{item.title}}
                    </div>
                </div>   
            </div>
        </div>
    </div>


</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";

  export default {
    data(){
        return{
            isShowMenuPanel:false,
            tel:'',//按手机搜索
        }
    },
    props:{
       canGoHome:{
           type:Boolean,
           default:true,
       },
       canGoAbout:{
           type:Boolean,
           default:true,
       },
       baseInfo:{
            type:Object,
            default:()=>{},
       }
    },
    inject:['getList'],
    methods:{
        // toggleLang(){
        //   this.$i18n.locale= this.lang == 'CN' ? 'TW' : 'CN';
        // },
        stopMove(){
            return;
        },
        toggleMenuPanel(){
            this.isShowMenuPanel = !this.isShowMenuPanel;
        },
        hideMenuPanel(){
            if(this.isShowMenuPanel)  this.isShowMenuPanel = false;
        },
        goOrder(){
            if(!this.tel){
                return;
            }
            this.$router.push({ name: 'order', params: { id: this.tel }});
        },
        goAbout(){
            this.$router.push({ name: 'rich', params: { name: 'about' }});
        },
        tapCat(catId){
           this.toggleMenuPanel();
           this.getList({cate_id:catId,isRefresh:true});
        },
         
    }
}
</script>

<style lang="scss" scoped>
//    @import "@/styles/variables.scss";
   .page-header{
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0;
        z-index: 10;
        left: 0;  
   }
   .page-header-placer{
        width: 100%;
        background: #fff;
        height: 1.00rem;
   }
   .inner{
       width: 100%;
       height: 1.00rem;
       padding:0 0.30rem;
       margin: 0 auto;
    //    background: #333;
   }
   .menu{
       font-size: 0.60rem;
       color:#333;
   }
   .search{
       margin-left: auto;
       width: 5.13rem;
       border: 1px solid #FC3538;
       border-radius: 0.60rem;
       overflow: hidden;
       background: #fff;
       .inpt{
          flex:1;
           height: 0.60rem;
           line-height: 0.60rem;
           padding: 0 0.30rem;
           color:#333;
           font-size: 0.26rem;
       }
       .s-btn{
            margin-left: auto;
            margin-right: -1px;
            width: 0.90rem;
            height: 0.60rem;
            background: #FC3538;
            border-radius: 0.30rem;
            flex-shrink: 0;
            .s-img{
                font-size: 0.30rem;
                color:#fff;
            }
       }
   }
   .lang{
        margin-left: 0.10rem;
        width: 0.50rem;
        height: 0.50rem;
        background: #FFFFFF;
        border: 1px solid #666666;
        border-radius: 50%;
        line-height: 0.50rem;
        text-align:center;
        font-size: 0.26rem;
        color: #333333;
        cursor: pointer;
        font-family: Source Han Sans CN;
        font-weight: 400;
   }
   .logo , .tab{
       display: none;
   }

   .menu-panel{
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
      width: 100%;
      height: 100%;
   }
    .menu-panel .mask{
       position: absolute;
       z-index: 5;
       left: 0;
       top:0;
       width: 100%;
       height: 100%;
    //    background: rgba(0,0,0,.4);
   }
    .menu-panel .content{
        background: #fff;
        border-top: 1px solid #eee;
        width: 100%;
        position: fixed;
        top: 0.99rem;
        z-index: 11;
        left: 0;  
        border-radius: 0rem 0rem 0.20rem 0.20rem;
        overflow: hidden;
   }
   .m-inner{
       width: 100%;
       flex-wrap: wrap;
   }
   .m-item{
        width: 20%;
        height: 0.80rem;
        line-height: 0.80rem;
        text-align: center;
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
   }
   // 1400px  1920px共有部分
   @media (min-width:769px){
        .page-header{
            position: relative;
        }
        .page-header-placer{
                display: none;
        }
        .menu{
            display: none;
        }  
        .inner{
            padding: 0;
            width: 12.00rem;
        }
        .logo{
            display: block;
            background: #f8f8f8;
            width: 1.02rem;
            height: 1.00rem;
            margin-right: 0.30rem;
            .img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .tab{
            font-family: Source Han Sans CN;
            font-weight: 400;
            display: block;
            color:#333;
            height: 1.00rem;
            min-width: 0.66rem;
            line-height: 1.00rem;
            text-align: center;
            margin-right: 0.30rem;
            cursor: pointer;
            &:hover{
                color: #FC3538;
            }
        }
        .search{
            width: 3.00rem;
            .inpt{
                height: 0.40rem;
                line-height: 0.40rem;
                font-size: 0.14rem;
                padding-left: 0.10rem;
            }
            .s-btn{
                width: 0.60rem;
                height: 0.40rem;
                .s-img{
                    font-size: 0.22rem;
                }
            }
        }
        .lang{
            width: 0.40rem;
            height: 0.40rem;
            border-radius: 50%;
            line-height: 0.40rem;
        }
        
        .menu-panel .mask{
            background: transparent;
        }
        .menu-panel .content{
                background: #fff;
                border-top: 1px solid #eee;
                width: 6.32rem;
                position: absolute;
                top: 0.99rem;
                z-index: 11;
                border-radius: 0rem 0rem 0.10rem 0.10rem;
                overflow: hidden;
        }
        .m-inner{
            width: 100%;
            flex-wrap: wrap;
        }
        .m-item{
                width: 20%;
                height: 0.65rem;
                line-height: 0.65rem;
                text-align: center;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #333333;
        }
   }   
    // 1200px 定死的话，大小rem不会对应了
   //    @media (min-width:1201px){
//         .inner{
//             width: 1200px !important;
//             max-width: 1200px;
//         }
//    }
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
        .tab{
            font-size: 0.16rem;
        }
        .lang{
            font-size: 0.14rem;
        }
        .menu-panel .content{
            left: 1.00rem;
        }
        .m-item{
            font-size: 0.16rem;
        }
   }
   //1920px为准
   @media (min-width:1401px){
        .tab{
            font-size: 0.20rem;
        }
        .lang{
            font-size: 0.18rem;
        }
        .menu-panel .content{
            left: 3.60rem;
        }
        .m-item{
            font-size: 0.20rem;
        }
   }
</style>
