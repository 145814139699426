<template>
    <div class="page-header">
        <div class="inner row">
            <span class="zzkicon-back" @click="goBack"></span>
            <p class="title clamp">{{$t('success.headerTitle')}}</p>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  export default {
    data(){
        return{

        }
    },
    methods:{

    }
}
</script>

<style lang="scss" scoped>
   .page-header{
        width: 100%;
        background: #EDEDED;
   }
   .inner{
       width: 100%;
       height: 0.90rem;
       padding-right: 0.30rem;
   }
   .zzkicon-back{
       color:#333333;
       font-size: 0.34rem;
       padding-left: 0.30rem;
       padding-right: 0.1rem;
       flex-shrink: 0;
   }
   .title{
        flex:1;
        height: 0.90rem;
        line-height: 0.90rem;
        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
   }


   // 1400px  1920px共有部分
   @media (min-width:751px){
        .inner{
            height: 45px;
            padding-right: 45px;
        }
        .zzkicon-back{
            font-size: 20px;
            padding-left: 15px;
            padding-right: 5px;
        }
        .title{
            height: 45px;
            line-height: 45px;
            font-size: 18px;
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
