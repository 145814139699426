<template>
    <div class="page-header">
        <!--         
        <div class="inner row">
            <span class="zzkicon-back" @click="goBack"></span>
            <p class="title clamp">{{title || '详情页'}}</p>
        </div>
         -->
        <div class="tw-tips row" v-if="isTw">
            <img src="../../../assets/img/perm/elphant-logo.png" class="img">
            <div class="name-wrap row">
                <p class="name">大象優選</p>
                <p class="name">鑒別服務</p>
                <p class="name">質量保證</p>
            </div>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  export default {
    data(){
        return{
            // IS_TW:false,

        }
    },
    props:['title',"isTw"],
    mounted(){
        // if(this.lang === config.TW){
        //     this.IS_TW=TRUE;
        // }
    },
    methods:{
        // toggleLang(){
        //   this.$i18n.locale= this.lang == 'CN' ? 'TC' : 'CN';
        // },
        // stopMove(){
        //     return;
        // },
    }
}
</script>

<style lang="scss" scoped>
   .page-header{
        width: 100%;
        background: #fff;
   }
   .inner{
       width: 100%;
       height: 0.90rem;
       padding-right: 0.30rem;
   }
   .zzkicon-back{
       color:#333333;
       font-size: 0.34rem;
       padding-left: 0.30rem;
       padding-right: 0.1rem;
       flex-shrink: 0;
   }
   .title{
        flex:1;
        height: 0.90rem;
        line-height: 0.90rem;
        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        text-align: center;
   }

   .tw-tips{
       background: #FC3538;
       height: 0.90rem;
       padding:0 0.30rem;
       .img{
           width: 0.60rem;
           height: 0.60rem;
           margin-right: 0.20rem;
           background: #FC3538;
       }
       .name-wrap{
           flex:1;
           justify-content: space-around;
           .name{
               color:#fff;
               font-size: 0.28rem;

           }
       }
   }


   // 1400px  1920px共有部分
   @media (min-width:751px){
        .tw-tips{
            height: 45px;
            padding:0 15px;
            .img{
                width: 30px;
                height: 30px;
                margin-right: 10px;
            }
            .name-wrap{
                .name{
                    font-size: 14px;
                }
            }
        }
        .inner{
            height: 45px;
            padding-right: 45px;
        }
        .zzkicon-back{
            font-size: 20px;
            padding-left: 15px;
            padding-right: 5px;
        }
        .title{
            height: 45px;
            line-height: 45px;
            font-size: 18px;
        }
   }
</style>
