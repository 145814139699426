<template>
   <div class="page">
     <div class="p-inner">
        <!-- <pageHeader :title="product_info.title" :isTw="isTw" /> -->


        <pageSlide :banners="banners" v-if="banners.length>0" /> 
        <!-- 产品名称 -->
        <pageInfo :product_info="product_info" :isTw="isTw" />  
        <!-- 详情页  -->
        <pageProfile ref="profileRef" :product_info="product_info" :isTw="isTw" />   
        <!-- 规格选择 -->
        <pageSpec  ref="specRef" :product_info="product_info"  :attr_group="attr_group" :combo="combo" :isTw="isTw" />
        <!-- 填写地址 -->
        <pageAddr  :shipping_list="shipping_list" :product_info="product_info" />
        <!-- 产品保证 -->
        <pageWarrant  />
        <!-- 固定部位 -->
        <pageFixed :isShowFixBottom="isShowFixBottom" :fb_message="product_info.fb_message" />
        <!-- 底部部位 -->
        <pageFooter /> 
     </div>
   </div>
</template>

<script>

   


  const TAG_NAME = "DETAIL.VUE";
  import config from '@/config.js';
//   import { MessageBox} from 'element-ui';
  import pageHeader from './Header.vue';
  import pageSlide from './Slide.vue';
  import pageFooter from './Footer.vue';
  import pageInfo from './Info.vue';
  import pageProfile from './Profile.vue';
  import pageSpec from './Spec.vue';
  import pageAddr from './Addr.vue';
  import pageWarrant from './Warrant.vue';
  import pageFixed from './Fixed.vue';
  import mixinResize from './js/mixinResize';
  import mixinScroll from './js/mixinScroll';

   //判断数组中是否包含某字符串
   Array.prototype.contains = function(needle) {
      for (i in this) {
         if (this[i].indexOf(needle) > 0)
               return i;
      }
      return -1;
   }



  export default {
    components:{
       pageHeader,
       pageSlide,
       pageFooter,
       pageInfo,
       pageProfile,
       pageSpec,
       pageAddr,
       pageWarrant,
       pageFixed
    },
    data(){
      return{
         product_info:{},
         banners:[],     

         attr_group:[],
         combo:[],

         shipping_list:[],
         isTw:false, //是否台湾，//
      }
    },
    props:['info'],
    mixins: [mixinResize,mixinScroll],
    provide () {
         return {
            backtop:  this.backtop,
            goToBuy: this.goToBuy,
            submit: this.submit,
         }
    },
   //  created(){
   //    const encode = this.$route.params.encode;
   //    this.init(encode);
   //  },
   watch:{
      info:{
         immediate: true,
         handler(val) {
            if(val && val.product_info && val.product_info.id){
               this.init(val)
            }
         }
      }
   },
    methods:{
        async init(val){
            // let res =await this.$http('product.info',{encode});
            
           
            const {attr_group=[],combo=[],product_info={},shipping_list=[]}=val;
            this.product_info = product_info;

            this.$i18n.locale =  config.lang.includes(product_info.lang_code)? product_info.lang_code : config.CN ;
           
            this.banners =(product_info.banner_images && product_info.banner_images.length>0 ) ? product_info.banner_images.split(',') : [];
            if(product_info.is_copy==1){
               this.disableCopy();
            }
            if(product_info.ico_image){
               this.$tools.change_icon(product_info.ico_image);
            }
            
            document.title ='';
            document.title = product_info.title;

            this.attr_group = attr_group;
            this.combo = combo;
            this.shipping_list = shipping_list;

            //判断是否台湾
            if(product_info.zone_id === 2){
               this.isTw = true;
            }


            if(this.product_info.fb_px){
               let fbs = this.product_info.fb_px.split(",");
               fbs.forEach(element => {
                   fbq('init',element); //'1055814575273109'
               });
               fbq('track', 'PageView');
            }
            if(this.product_info.tk_px){
               // ttq.load('C8PTJKVV9S6N3MLDBN10');
               // ttq.load('C8PUL77V9S6N3MLDBOF0');
               let tks = this.product_info.tk_px.split(",");
               tks.forEach(element => {
                  ttq.load(element);
               });
               ttq.page();
            }
            if(this.product_info.line_px){
               // '4b231b55-ad4e-45b6-bb20-0632fb6981dc'
               _lt('init', {
               customerType: 'lap',
               tagId: this.product_info.line_px
               });
               _lt('send', 'pv', [this.product_info.line_px]);
            }
            
        },
        getMobileOS(){
            var device_type = navigator.userAgent; //获取userAgent信息
            var md = new MobileDetect(device_type); //初始化mobile-detect
            var os = md.os(); //获取系统
            var model = "";
            if (os == "iOS") { //ios系统的处理
               os = +md.version("iPhone");
               model = md.mobile();
            } else if (os == "AndroidOS") { //Android系统的处理
               os = md.os() + md.version("Android");
               var sss = device_type.split(";");
               try{
                  var i = sss.contains("Build/");
                  if (i > -1) {
                        model = sss[i].substring(0, sss[i].indexOf("Build/"));
                  }  
               }catch(e){
                  model='';
               }
            }else{
               os="PC";
               model='';
            }
            // console.log(os + "---" + model);//打印系统版本和手机型号
            return  os + model;
        },
        //处理f12右键
        disableCopy(){
            window.onkeydown = window.onkeyup = window.onkeypress = function (event) {  
               // 判断是否按下F12，F12键码为123  
               if (event.keyCode == 123) {  
                  event.preventDefault(); // 阻止默认事件行为  
                  window.event.returnValue = false;  
               }  
            }
            window.oncontextmenu = function(event) {  
               event.preventDefault(); // 阻止默认事件行为  
               return false;  
            }
        },



        //由地址组件提交后，父统一处理
        async submit(addrData){
           if(this.isSubmitted){
              return;
           }
           if(this.$tools.inAction('submit',this)){
              return;
           }
           let proData = this.$refs.specRef.getProData();
           const sendData ={
              ...addrData,
              ...proData,
              mobile_brand:this.getMobileOS()
           }
            //  console.log(TAG_NAME,sendData);
           let res = await this.$http('order.buy',sendData);
            if(this.product_info.fb_px){
              fbq('track', 'Purchase', {currency: this.product_info.symbol_left, value: proData.price});
            }
            if(this.product_info.tk_px){
               ttq.track('AddToCart',{  
                  // content_id: '301',
                  // quantity: 1,
                  // price: 8,
                  value: proData.price,
                  currency: this.product_info.symbol_left,
               })
            }
            if(this.product_info.line_px){
               _lt(
                  'send', 
                  'cv', 
                  { type:'Conversion'},
                  [this.product_info.line_px]
               );
            }

           
          
           this.isSubmitted=true;
           this.$router.push({ name: 'success', params: { id: res.data }});
        },
      }
   }
</script>

<style lang="scss" scoped>
  .page{
      background: #f1f1f1;
      min-height: 100vh;
  }
  .p-inner{
      background: #f8f8f8;
      min-height: 100vh;
      padding-bottom: 2.80rem;
      position: relative;
      width: 100%;
      max-width: 750px;
      margin:0 auto;
  }
   @media (min-width:751px){
      .p-inner{
          padding-bottom: 140px;
          
      }
      // 本页只有两种布局，区别首页
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */    color:    #CCCCCC; font-size:12px;}
      
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    #CCCCCC;font-size: 12px; }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */   color:    #CCCCCC;font-size: 12px; }
      
      ::placeholder { color: #999; font-size: 12px; }
   }

</style>
