const TAG_NAME = "MIXIN_SCROLL.JS";
export default {
    data(){
        return{
           isShowFixBottom:true,
           timer:'',
           specRef:'',
           profileRef:'',
        }
    },
  mounted() {
    window.addEventListener("scroll",this.showbtn,true);
    this.specRef = this.$refs.specRef;
    this.profileRef = this.$refs.profileRef;
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.showbtn); 
    if(this.timer){
        clearInterval(this.timer);
        this.timer = null;
    }
  },
  
  methods: {
    // 显示回到顶部按钮
    showbtn(){
        //滚动高度，目标高度
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        //该高度不准备，scrollTop为已滑上高度。不满足选择规格出现高度。
        let targetH = this.profileRef.$el.offsetHeight ;
        // console.log(TAG_NAME,scrollTop,targetH)
        if(scrollTop >= targetH){
            this.isShowFixBottom = false;
        }else{
            this.isShowFixBottom = true;
        }
    },
    backtop(){
        // window.scroll(0,0);
        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        this.timer = setInterval(()=>{
            // console.log(TAG_NAME,'定时中...')
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-scrollTop / 3); //必须floor, ceil大会有0情况
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop + ispeed;
            if(scrollTop === 0){
                clearInterval(this.timer);
                this.timer = null;
            }
        },30)
    },
    goToBuy(){
        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        this.timer = setInterval(()=>{
            // console.log('定时---');
            let targetTop = this.specRef.$el.offsetTop;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            let distance = Math.abs(targetTop -  scrollTop);
            let ispeed = Math.ceil(distance / 2);  //必须ceil，floor小会有0情况。
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop + ispeed;
            if(scrollTop >= targetTop){
                clearInterval(this.timer);
            }
        },30);
    },
  }
}
