<template>
    <div class="page-fixed">
        <div class="f-bottom row" v-show="isShowFixBottom">
            <div class="b-l column" v-if="fb_message" @click="goFbMessenger">
                <img src="../../../assets/img/perm/messager.png" class="img">
                <p class="txt">messenger</p>
            </div>
            <div class="b-r" @click="goToBuy">{{$t('detail.buyNow')}}</div>
        </div>
        <div class="f-right column">
            <div class="r-msg center" v-if="fb_message" @click="goFbMessenger">
                <img src="../../../assets/img/perm/messager.png" class="img">
            </div>
            <div class="r-top center" @click="backtop">
                <span class="zzkicon-fanhuidingbu"></span>
            </div>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_FIXED.JS";
  var timer;
  export default {
    data(){
        return{

        }
    },
    props:['isShowFixBottom','fb_message'],
    inject:['goToBuy','backtop'],
    methods:{
        goFbMessenger(){
            if(!this.fb_message){
                return;
            }
            window.open('https://www.facebook.com/messages/t/'+this.fb_message)
        }
    }
}
</script>

<style lang="scss" scoped>
    .f-bottom{
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: #fff;
        width: 100%;
        height: 0.90rem;
    }
    .b-l{
        width: 1.88rem;
        height: 0.90rem;
        align-items: center;
        justify-content: center;
        .img{
            width: 0.54rem;
            height: 0.54rem;
        }
        .txt{
            font-size: 0.24rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
    .b-r{
        flex: 1;
        height: 0.90rem;
        background: #FC3538;
        line-height: 0.90rem;
        text-align: center;

        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
    
    .f-right{
        bottom: 2.42rem;
        position: fixed;
        right: 0.30rem;

        width: 0.70rem;
        // height: 1.40rem;
        background: #313131;
        // border: 1px solid #CCCCCC;
        border-radius: 0.10rem;
    }
    .r-msg{
        // flex:1;
        height: 0.7rem;
        width: 100%;
        .img{
            width: 0.42rem;
            height: 0.42rem;
        }
    }
    .r-top{
        // flex:1;
        height: 0.7rem;
        width: 100%;
        .zzkicon-fanhuidingbu{
            font-size: 0.42rem;
            color:#fff;
        }
    }
    

   // 1400px  1920px共有部分
   @media (min-width:751px){
            .f-bottom{
                width: 750px;
                height: 45px;
                left: 50%;
                transform: translateX(-50%);
            }
            .b-l{
                width: 188px;
                height: 45px;
                .img{
                    width: 27px;
                    height: 27px;
                }
                .txt{
                    font-size: 12px;
                }
            }
            .b-r{
                flex: 1;
                height: 45px;
                line-height: 45px;

                font-size: 15px;
            }
            .f-right{
                bottom: 121px;
                right: 15px;
                width: 50px;
                // height: 100px;
                border-radius: 5px;
            }
            .r-msg{
                // flex:1;
                height: 50px;
                width: 100%;
                .img{
                    width: 28px;
                    height: 28px;
                }
            }
            .r-top{
                height: 50px;
                .zzkicon-fanhuidingbu{
                    font-size: 30px;
                }
            }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
