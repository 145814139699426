import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state:{
    isLoading:'loaded', //正在加载中 loading,loaded
	device: 'mobile',
  },
  mutations:{
	TOGGLE_DEVICE: (state, device) => {
	  state.device = device
	},
	//更新state数据
	setStateAttr(state, param){
		//console.log("setStateAttr",param);
		if(param instanceof Array){
			for(let item of param){
				state[item.key] = item.val;
			}
		}else{
			state[param.key] = param.val;
		}
	},
  },
  actions: {
	toggleDevice({ commit }, device) {
	  commit('TOGGLE_DEVICE', device)
	},
  },
  modules: {}
});
//state,mutations,actions分出去export default { ... }
