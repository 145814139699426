export const pageHeader = {
    sTips: '输入手机号或订单号搜索订单',
    tabHome: 'Home',
    tabCat: 'Categories',
    tabAbout: 'ABOUT US',
}
export const pageFooter = {
    more: 'More information',
    about:'ABOUT US',
    item:'Terms of Service',
    privacy:'Privacy Statement',
    exchange:'Returns & Refund Policy'
}
export const home = {
    litTitle: 'All products',
}

export const detail = {
    soldTxt: 'Sold',
    serviceTime: 'Returns Within 15 Days',
    servicePay: 'Cash on delivery',
    serviceShip: 'Free shipping',
    buyNow: 'Buy Now',
    detailTxt:'Description',
   
    combo:'Combo',
    specTitle:'Style',
    specAmountTxt:'total',
 
    // addrTitle:'Shipping Address',
    addrTitle:'Shipping Address',
    addrName:'Name',
    addrNameP:'Please enter consignee name',
    addrMobile:'Phone',
    addrMobileP:'Please fill in the phone number',
    addrShiper:'配送方式',
    addrShiperP:'请选择配送方式',
    addrAddr:'State/Area',
    addrAddrP:'Area',
    addrAddrS:'State',
    addrShopP:'选择门店',
    // addrAddrP:'请填写城市、地区',
    addrDetail:'Address',//Shipping Address
    addrStoreName:'商铺名',
    addrStoreNum:'商铺号',
    addrDetailP:'Please fill in the detailed address',
    addrEmail:'Mail',
    addrEmailP:'please enter your email',
    addrCode:'ZIP code',
    addrCodeP:'Please fill in the zip code',
    addrNote:'note',
    addrNoteP:'Please enter a note',
    addrPay:'Payments',
    addrPayV:'Cash on delivery',
    addrBtn:'Complete Order',

    wTitle:'Buyer Protection',
    wTxt1:'If you do not receive the goods, a full refund',
    wTxt2:'Full refund if your purchase and instructions do not meet all or part of the requirements',
}
export const detailTwo = {
    // soldTxt: '售出',
    // serviceTime: '15天鑒賞期',
    // servicePay: '貨到付款',
    // serviceShip: '免運費',
    buyNow: 'Quick online order',
    // detailTxt:'商品詳情',
  
    combo:'Combo',
    // specTitle:'選擇規格',
    specAmountTxt:'total',
  
    // addrTitle:'填寫信息',
    addrName:'Name',
    addrNameP:'Please enter consignee name',
    addrMobile:'Phone',
    addrMobileP:'Please fill in the phone number',
    addrShiper:'付款方式',
    addrShiperP:'请选择付款方式',
    addrAddr:'State/Area',
    addrAddrP:'Area',
    addrAddrS:'State',
    addrShopP:'选择门店',
    // addrAddrP:'請填寫城市、地區',
    addrDetail:'Address',//Shipping Address
    addrStoreName:'商铺名',
    addrStoreNum:'商铺号',
    addrDetailP:'Please fill in the detailed address',
    addrEmail:'Mail',
    addrEmailP:'please enter your email',
    addrCode:'ZIP code',
    addrCodeP:'Please fill in the zip code',
    addrNote:'note',
    addrNoteP:'Please enter a note',
    addrPay:'Payments',
    addrPayV:'Cash on delivery',
    addrBtn:'Complete Order',

    // wTitle:'買方擔保',
    // wTxt1:'如果妳沒有收到貨物，全額退款',
    // wTxt2:'如果您購買的産品和說明不符合全部或部分要求，則全額退款',
    eTitle: 'Product Reviews',
    nTitle: 'Shopping Notes',
  }

export const success = {
    headerTitle:'Order successfully',
    sucTitle:'Order successfully!',
    sucTipsT:' Thanks，Purchased！We will be shipped as soon！Cash on delivery +Free shipping ！',
    sucTipsB:'Add seller Whatsapp for logistics tracking and after-sales service, thank you',
    oTime:'date',
    oNo:'order number',
    oPay:'Payments',
    oShip:'shipping fee',
    oTotal:'Total',
    cTitle:'Scan QR code: Add seller WhatsApp',
    cTips:'',
}
export const order = {
    headerTitle:'My Order',
    oNo:'order number',
    oTime:'order date',
    oName:'Name',
    oMobile:'Phone',
    oShiper:'配送方式',
    oAddr:'Address',
    oEmail:'mail',
    oNote:'note',
    oPay:'Payments',
}








