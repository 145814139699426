<template>
    <div class="page-slide">
	    <slick
            ref="slick"
            :options="slickOptions"
            @afterChange="handleAfterChange"
        >
            <img :src="item" alt="" v-for="item in banners" :key="item">
        </slick>
        <!-- 判断大于1才展示 -->
        <div class="dots-wrap">
            {{curSlide +'/'+ banners.length}}
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  import Slick from 'vue-slick';

  export default {
    components: { Slick },
    data(){
        return{
            curSlide:1,
            slickOptions: {
                autoplay:true,
                arrows:false,
                dots:false,
                pauseOnHover:false,
            },
        }
    },
    props:['banners'],
    methods:{
        handleAfterChange(event, slick, currentSlide) {
            // console.log('handleAfterChange', event, slick, currentSlide);
            this.curSlide = currentSlide + 1;
        },
    },
}
</script>

<style lang="scss" scoped>
  
   .page-slide{
       width: 100%;
       height: auto;
       position: relative;
       line-height: 0;
   }
   .dots-wrap{
        position: absolute;
        bottom: 0.20rem;
        right: 0.30rem;
        width: 0.80rem;
        height: 0.40rem;
        background: rgba(0,0,0,.3);
        border-radius: 0.20rem;
        text-align:center;
        line-height: 0.40rem;
        color:#fff;
        font-size: 0.26rem;
   }
   img{
       //max-height: 2.74rem;
       width: 100%;
       object-fit:cover;
   }
   // 1400px  1920px共有部分
   @media (min-width:751px){
        .dots-wrap{
                bottom: 10px;
                right: 15px;
                width: 40px;
                height: 20px;
                border-radius: 10px;
                line-height: 20px;
                font-size: 14px;
        }
   }   
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){

   }
   //1920px为准
   @media (min-width:1401px){

   }
</style>
