<template>
    <div class="pagination center">
        <!-- 
        <div class="load row">
            <span class="zzkicon-loading" v-if="loadingType=='loading'"></span>
            <span class="txt">{{loadingType=='loading'?'':(loadingType=='noMore'?'':'')}}</span>
        </div>
        -->
        <el-pagination
        background
        layout="prev, pager, next"
        :hide-on-single-page="true"
        :current-page="page"
        :page-size="pageSize"
        :total="listInfo.total"

        @current-change="chgePage"
        >
        </el-pagination>   
    </div>
</template>
<script>
  const TAG_NAME = "PAGE_PAGE.JS";
  import Vue from 'vue';
  import { Pagination} from 'element-ui';
  Vue.use(Pagination);
  export default {
    data(){
        return{
            // page:1,
            // pageSize:10,
            // loadingType:'more',
        }
    },
    props:['listInfo','page','pageSize'],
    mounted(){
        if(this.device === 'mobile'){
            window.addEventListener("scroll", this.onScroll);
        }
        // this.loadMore();
    },
    beforeDestroy(){
        if(this.device === 'mobile'){
            // console.log(TAG_NAME,'beforeDestroy')
            window.removeEventListener("scroll", this.onScroll);
        }
    },
    inject:['getList'],
    methods:{
        chgePage(page){
            this.$emit('update:page', page-1);
            this.getList({isPcDone:true});
        },
        onScroll(){
            if(this.device !== 'mobile'){
                return;
            }
            //可滚动容器的高度
			let containerEl = this.$parent.$el;
            let innerHeight = containerEl.offsetHeight + containerEl.offsetTop - 50;
			//屏幕尺寸高度
			let outerHeight = document.documentElement.clientHeight;
			//可滚动容器超出当前窗口显示范围的高度
			 let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			//避免切换时读取到异常高度
			if(scrollTop==0){
				innerHeight=10000
			}
			//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
			// console.log(innerHeight + " " + outerHeight + " " + scrollTop);
			// if(this.isLoad){
			// 	console.log(1)
            if( innerHeight <= outerHeight + scrollTop){
                // this.loadMore();
                this.getList();
			}
        },
    }
}
</script>


<style>
    .el-pagination{
        display: none !important;
    }
   
    @media (min-width:769px){
        .el-pagination{
            display: block !important;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #FC3538 !important;
        }
    }
</style>
<style lang="scss" scoped>
    .load{
        width: 100%;
        height: 0.60rem;
        font-size: 0.24rem;
        justify-content: center;
        display: flex;
        display: none;
    }
   .zzkicon-loading{
      color:#333;
      font-size: 0.32rem;
      animation: loading 1s linear infinite;
      margin-right: 0.20rem;
   }
   	@keyframes loading {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);;
		}
	}
    @media (min-width:769px){
        .load{
            display: none;
        }
    }
</style>
