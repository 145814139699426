/**
 * 接口列表文件
 * auth代表接口是否需要token
 * name:Request内做判断,url,auth,method,cache:{type: local,session, key:非必填}
 */
export default {
    /**  CONFIG ↓ **/
	baseconfig: {
		website: {
			url: 'api/baseconfig/website',
			method: 'POST',
			isNprogress:true,
			cacheType: 'session',
		}
	},
	/** 产品 ↓ **/
	product: {
		lists: {
			url: 'api/product/lists',
			method: 'POST',
			isNprogress:true,
		},
		info:{
			url: 'api/product/info',
			method: 'POST',
			isNprogress:true,
		}
	},
	/** 富文本 ↓ **/
	basedata: {
		find: {
			url: 'api/basedata/find',
			method: 'POST',
			isNprogress:true,
		},
	},
    /** 地址 ↓ **/
	region:{
        getprovince: {
			url: 'api/region/getprovince',
			method: 'POST',
			isNprogress:true,
			cacheType: 'session',
		},
		getarea: {
			url: 'api/region/getarea',
			method: 'POST',
			isNprogress:true,
		},
		getvillage: {
			url: 'api/region/getvillage',
			method: 'POST',
			isNprogress:true,
		},
		//泰国邮编
		getTHAZipCode: {
			url: 'api/region/getvillage_zipcode',
			method: 'POST',
			isNprogress:true,
		},
	},
	/** 商超 ↓ **/
    Familymart:{
        lists: {
			url: 'api/Familymart/lists',
			method: 'POST',
			isNprogress:true
		},
	},
	/** 订单 ↓ **/
	order:{
		buy: {
			url: 'api/order/buy',
			method: 'POST',
			isNprogress:true
		},
		lists: {
			url: 'api/order/lists',
			method: 'POST',
			isNprogress:true
		},
		info: {
			url: 'api/order/info',
			method: 'POST',
			isNprogress:true
		},
	},

	
};
