<template>
    <div class="page-profile">
        <!-- <h1 class="title">{{$t('detail.detailTxt')}}</h1> -->
        <!-- 富文本 -->
        <div class="rich" >
            <div v-html="richTxt || ''"></div>
            <img :src="product_info.size_image" class="size-img" v-if="product_info.size_image">
        </div>
       
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_PROFILE.JS";
  export default {
    data(){
        return{
           richTxt:'',
        }
    },
    props:['product_info'],
    watch:{
        product_info:{
            //content为父组件异步获取，钩子取不到
            handler(v){
                this.richTxt = this.replaceDetail(v.content);
            },
            immediate:true
        }
    },
    inject:['goToBuy'],
    methods:{
        replaceDetail(details = ''){
                //newContent仅是details替换后内容;
            let newContent = details.replace(/<img[^>]*>/gi, function (match, capture) { //去除三标签
                match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                return match;
            });
            newContent = newContent.replace(/<br[^>]*\/>/gi, '');
            newContent = newContent.replace(/<img/gi, '<img style="width:100%;height:auto;display:block;margin:0 auto;"');
            return newContent;
        },
    }
}
</script>

<style lang="scss" scoped>
   .page-profile{
        width: 100%;
        // background: #fff;
        border-top-left-radius: 0.20rem;
        border-top-right-radius: 0.20rem;
        overflow: hidden;
        // padding: 0 0.30rem;
   }
   .rich{
        width: 100%;
        background: #fff;

        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.40rem;
        text-align: justify;
       .size-img{
           width: 100%;
           height: auto;
       }
   }

    // 1400px  1920px共有部分
    @media (min-width:751px){
        .page-profile{
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }
</style>
