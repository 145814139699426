<template>
   <div class="page">
     <div class="p-inner">
        <pageHeader />
        <pageProj :datas="datas" />
     </div>
   </div>
</template>

<script>
  const TAG_NAME = "DETAIL.VUE";
  import pageHeader from './Header.vue';
  import pageProj from './Proj.vue';
   import mixinResize from './js/mixinResize';
   import mixinScroll from './js/mixinScroll';
 
  export default {
    components:{
       pageHeader,
       pageProj,
    },
    data(){
        return{
           
        }
    },
    mixins: [mixinResize,mixinScroll],
    created(){
     
    },
    mounted(){
      
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
  .page{
      background: #f1f1f1;
     
  }
   .p-inner{
      min-height: 100vh;
      background: #f8f8f8;
      width: 100%;
      max-width: 750px;
      margin:0 auto;
      padding-bottom: 0.30rem;
  }
 
   @media (min-width:751px){
      // 本页只有两种布局，区别首页
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */    color:    #CCCCCC; font-size:12px;}
      
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    #CCCCCC;font-size: 12px; }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */   color:    #CCCCCC;font-size: 12px; }
      
      ::placeholder { color: #999; font-size: 12px; }
   }

</style>
