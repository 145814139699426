<template>
    <div class="page-footer">
        <div class="inner">
            <div class="title">
                {{$t('pageFooter.more')}}
            </div>
            <div class="content row">
                <div class="c-item" @click="goRich('about')">
                    {{$t('pageFooter.about')}}
                </div>
                <div class="c-item" @click="goRich('item')">
                    {{$t('pageFooter.item')}}
                </div>
                <div class="c-item" @click="goRich('privacy')">
                    {{$t('pageFooter.privacy')}}
                </div>
                <div class="c-item" @click="goRich('exchange')">
                    {{$t('pageFooter.exchange')}}
                </div>
            </div>
            <!-- 
            <div class="banquan">
                ©2021 - 2026 ?????? ALL RIGHT RESERVED
            </div>     
            -->
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_FOOTER.JS";

  export default {
    data(){
        return{
        }
    },
    methods:{
        goRich(type){
             this.$router.push({ name: 'rich', params: { name: type }});
        }
    }
}
</script>

<style lang="scss" scoped>
   .page-footer{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #000;
        height: 2.50rem;
        z-index: 0.10;
   }
   .inner{
        width: 100%;
        padding: 0.58rem 0.30rem 0;
        margin: 0 auto;
   }
   .title{
        font-size: 0.30rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
   }
   .content{
       width: 100%;
       justify-content: space-between;
       height: 1.05rem;
   }
   .c-item{
        font-size: 0.26rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #FFFFFF;
        opacity: 0.6;
        cursor: pointer;
   }
   .banquan{
       width: 100%;
       text-align:center;
       height: 0.50rem;
       line-height: 0.50rem;
       font-size: 0.24rem;
       font-family: Source Han Sans CN;
       font-weight: 400;
       color: #FFFFFF;
       opacity: 0.6;
   }
    // 1400px  1920px共有部分
   @media (min-width:769px){
        .page-footer{
             height: 1.80rem;
        }
        .inner{
            padding: 0.32rem 0 0;
            width: 12.00rem;
        }
        .title{
            font-size: 0.24rem;
        }
        .content{
            height: 0.80rem;
        }
        .c-item{
            font-size: 0.14rem;
        }
        .banquan{
            font-size: 0.14rem;
        }
   }
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
        
        
   }
   //1920px为准
   @media (min-width:1401px){
       
   }

</style>
