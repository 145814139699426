<template>
    <div class="page-content">
        <div class="inner">
            <h1 class="title">{{title}}</h1>
            <p v-html="content"></p>
        </div>
        
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_CONTENT.JS";

  export default {
    data(){
        return{
        }
    },
    props:['title','content'],
    methods:{

    }
}
</script>

<style lang="scss" scoped>
  *{
      font-size: 14px;
      line-height: 20px;
  }
   .page-content{
        width: 7.50rem;
        background: #fff;
        margin:0 auto;
        flex:1;
   }
   .inner{
        width: 100%;
        padding: 0.30rem;
        margin:0 auto;
   }
      // 1400px  1920px共有部分
   @media (min-width:751px){
        .page-content{
                width: 750px;
        }
        .inner{
            padding: 15px;
        }
        
   } 
//    @media (min-width:769px){
//         .page-content{
//                 width: 100%;
//                 max-width: 7.50rem;
//                 background: #fff;
//                 margin:0 auto;
//         }
//        .inner{
//            width: 12.00rem;
//            padding: 0.30rem 0;
//        }
//    }   
//     // 1200px 定死的话，大小rem不会对应了
//    //    @media (min-width:1201px){
// //         .inner{
// //             width: 1200px !important;
// //             max-width: 1200px;
// //         }
// //    }
//    // 1400px为准
//    @media (min-width:769px) and (max-width: 1400px){
//         .tab{
//             font-size: 0.16rem;
//         }
//         .lang{
//             font-size: 0.14rem;
//         }
//         .menu-panel .content{
//             left: 1.00rem;
//         }
//         .m-item{
//             font-size: 0.16rem;
//         }
//    }
//    //1920px为准
//    @media (min-width:1401px){
//         .tab{
//             font-size: 0.20rem;
//         }
//         .lang{
//             font-size: 0.18rem;
//         }
//         .menu-panel .content{
//             left: 3.60rem;
//         }
//         .m-item{
//             font-size: 0.20rem;
//         }
//    }
</style>
