import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import mixin from './mixin';
import './permission' // permission control
import tools from './assets/js/tools';
import http from './request/index';
import { Message} from 'element-ui';
import { getLang } from '@/assets/js/cookie.js';
import VueI18n from 'vue-i18n'
import 'slick-carousel/slick/slick.css';
import config from '@/config';
// import './styles/variables.scss' 不起作用

const TAG_NAME = 'MAIN.JS';

const bus = new Vue();

Vue.prototype.$bus = bus;
Vue.prototype.$message = Message;
// import { MessageBox} from 'element-ui';
// MessageBox.confirm('确定要退出吗？', '提示', {
//   confirmButtonText: '确定',
//   cancelButtonText: '取消',
//   type: 'warning'
// }).then(() => {
//   this.$router.replace({path: '/login'});
// }).catch(() => {
//   this.$message({
//       type: 'info',
//       message: '已取消删除'
//   });          
// });
//success/warning/info/error
Vue.prototype.$tools = tools;
Vue.prototype.$http = http;


Vue.use(VueI18n) 
const i18n = new VueI18n({
  locale: getLang() || config.CN,    // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  //{{$t('prodct.bItemCheckDetails')}}动态展示值
  messages: {
    [config.CN]: require('./lang/cn'),   // 中文语言包
    [config.TW]: require('./lang/tw'),    
    [config.THA]: require('./lang/tha'),    
    [config.EN]: require('./lang/en'),
  }
})


Vue.mixin(mixin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");


