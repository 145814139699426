import apiList from './api'
import axios from 'axios'; // 引入axios
import QS from 'qs';
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import Vue from "vue";
import config from '@/config.js'
NProgress.configure({ showSpinner: false }) 
// import store from '@/store/index';
// import Tools from '../js/tools'
// import {router} from '../../router/index'

if (process.env.NODE_ENV == 'development') {    
    // axios.defaults.baseURL = '/api'; //api.js已配置
    axios.defaults.baseURL = '/'; //如不加/rich/about; 请求时会带上/rich/...--vue.config.js内配
}else if(process.env.NODE_ENV == 'production') {    
	axios.defaults.baseURL = config.baseURL;
}
axios.defaults.withCredentials=false;
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';


let cacheSessions = {}; //会话存储
const TAG_NAME="REQUEST";
// axios.interceptors.response.use(    
// 	response => {
// 			//200情况
		
// 		return Promise.resolve(response);
// 	},
// 	error => {
// 		console.log("axios.interceptors.response error",error,error.statusCode); 
// 		Vue.prototype.$message({message:'请求失败',type:'error'});
// 		return Promise.reject(error);    
// 	}    
// );
export default function api(url, params = {}) {
	let api = getApiObj(url);
	// console.log(TAG_NAME,api)
    let cacheType = api.cacheType || "";
	const cacheKey = getCacheKey(url,params)
	// axios.interceptors.response.use针对所有请求，会缓存之前请求。即response同一个，但url之前所有请求再处理一篇
	// axios.interceptors.response.use(    
	// 	response => {
	// 		 //200情况
	// 		 const data = response.data;
	// 		 if( data && data.code === 1){
	// 			if(cacheType === 'local') localStorage[cacheKey] = data;
	// 			if(cacheType === 'session') {
	// 				cacheSessions[cacheKey] = JSON.stringify(data || '');
	// 				console.log(TAG_NAME,'sessions',cacheSessions[cacheKey]);
	// 			}
	// 		 }

	// 		return Promise.resolve(response);
	// 	},
	// 	error => {
	// 		console.log("axios.interceptors.response error",error,error.statusCode); 
	// 		Vue.prototype.$message({message:'请求失败',type:'error'});
	// 		return Promise.reject(error);    
	// 	}    
	// );

    return new Promise((resolve, reject) => {
		//请求之前处理===================
		let res;
		if( cacheType === 'local'){
			res = localStorage[cacheKey];
		}else if( cacheType === 'session'){
			// 第一次session--要判断否则JSON.parse报错
			if(cacheSessions[cacheKey]) res = JSON.parse(cacheSessions[cacheKey]);
		}
		if(res){
			resolve(res);
			// console.log(TAG_NAME,'已获得请求值，不再请求---',cacheType,res);
			return;
		}

		params= Object.assign(params,{website:window.location.host});
        api.isNprogress && NProgress.start();
        axios.post(api.url, QS.stringify(params))
        .then(res => {
			api.isNprogress && NProgress.done()
			//请求之后处理=============================
			const data = res.data;
			if( data && data.code === 1){
			   if(cacheType === 'local') localStorage[cacheKey] = data;
			   if(cacheType === 'session') {
				   cacheSessions[cacheKey] = JSON.stringify(data || '');
				//    console.log(TAG_NAME,'sessions',cacheSessions[cacheKey]);
			   }
			   resolve(res.data);
			}else{
			   // console.log(data);
               Vue.prototype.$message({message:data && data.msg ? data.msg :'请求失败',type:'error'});
			   reject(false);
			}
        })
        .catch(err =>{
			api.isNprogress &&  NProgress.done()
			// console.log('err---',err);
			Vue.prototype.$message({message:'请求失败',type:'error'});
            reject(err)
        })
    });
}

function getApiObj(url) {
	let apiArray = url.split(".");
	let api = apiList;
	apiArray.forEach(v => {
		api = api[v];
	});
	return api;
}
function getCacheKey(url,data={}){
	// mydate.getDay()+ mydate.getHours()+ mydate.getMinutes()+mydate.getSeconds()+mydate.getMilliseconds()+ Math.round(Math.random() * 10000);
	const key = url;
	return  "cache-"+key+JSON.stringify(data)
}
