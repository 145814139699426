<template>
   <div class="page">
	   <pageHeader :canGoHome="false" :baseInfo="baseInfo" />
       <pageSlide ref="slideRef" :banners="banners" v-if="banners.length>0"  />
       <pageList :datas="list" />
       <pagePagination :listInfo="listInfo" :page.sync="page" :pageSize="pageSize"/>
       <pageFooter />
   </div>
</template>

<script>
  import pagePagination from './Pagination.vue';
  // import config from '@/config';
  const TAG_NAME="HOME INDEX";
  import pageHeader from '@/components/HomeHeader.vue';
  import pageSlide from './Slide.vue';
  import pageList from './List.vue';
  import pageFooter from '@/components/HomeFooter.vue';
  import mixinResize from './js/mixinResize'
  export default {
    components:{
       pageHeader,
       pageSlide,
       pageList,
       pagePagination,
       pageFooter,
    },
    data(){
        return{
            baseInfo:{},//基础信息
            banners:[],
            
            listInfo:{},
            catId:'',//当前分类id
            list:[],//列表数据
            
            page:0,
            pageSize:6,
            loadingType:'more',
        }
    },
    mixins: [mixinResize],
    beforeRouteEnter (to, from, next) {
        // window.scroll(0, 0)
        next(vm => {
            if(from.name){
                //初始会init，但返回该页时偶尔会空，过会才显示。故调刷新方法
                vm.$refs.slideRef && vm.$refs.slideRef.reInit();
            }
            return true;
        });
    },
    mounted(){
        this.getBaseInfo();
        this.getList();
    },
    provide(){
        return{
           getList:this.getList
        }
    },
    methods:{
        async getBaseInfo(){
            let res =await this.$http('baseconfig.website');
            this.baseInfo = res.data || {};
            let imgs = this.baseInfo.banner_images|| '';
            this.banners =(imgs && imgs.length>0 ) ? imgs.split(',') : [];
            // console.log(TAG_NAME,res)
            // app.vue已确定，全局应用
            // this.$i18n.locale =  config.lang.includes(res.data.lang_code)? res.data.lang_code : config.CN ;
             if(this.baseInfo.icon_image){
               this.$tools.change_icon(this.baseInfo.icon_image);
            }
            document.title ='';
            document.title = window.location.host;
        },
        async getList(opt={}){
           let {cate_id=this.catId,isRefresh=false,isPcDone=false} = opt;
            //点击分类时，重新请求
            if(isRefresh){
                this.loadingType = 'more';
                this.page=0;
                this.list=[];
                this.listInfo={};
                this.catId=cate_id || '';
            }
            if(isPcDone){
                this.loadingType = 'more';
                this.list=[];
            }
            if(this.loadingType == 'noMore' || this.loadingType == 'loading'){
                return;
            }
            //  console.log(TAG_NAME,'触底了')
            this.loadingType = 'loading';
            this.page++;
            let res =await this.$http('product.lists',{cate_id,page:this.page,listrow:this.pageSize});
            this.listInfo = res.data || {};
            const result= res.data.data || [];
            if(result.length < this.pageSize){
                this.loadingType = 'noMore'
            }else{
                this.loadingType = 'more'
            }
            this.list= this.list.concat(result);
        },

    }
}
</script>

<style lang="scss" scoped>
   .page{
      background: #f8f8f8;
      min-height: 100vh;
      padding-bottom: 2.80rem;
      position: relative;
   }
   @media (min-width:769px){
       .page{
            padding-bottom: 2.00rem;
        }
   }
</style>
