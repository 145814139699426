import router from './router'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
// import { getToken } from '@/assets/js/auth' // get token from cookie
// import http from '@/assets/js/http' // get token from cookie

// NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach( async (to, from, next) => {
  // document.title = to.meta.title || '公共卫生查询'
    // NProgress.start()
    next()
})
router.afterEach(() => {
  // NProgress.done()
})
