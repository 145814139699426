import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/home/index.vue";
import detail from "../views/detail/index.vue";
// import detailTwo from "../views/detailTwo/index.vue";
import success from "../views/success/index.vue";
import order from "../views/order/index.vue";
import rich from "../views/rich/index.vue";

Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "home",
    component: home,
    meta:{
      keepAlive:true,   //false每次进入都mounted,不复用组件; true直接再使用上次
    }
  },
  {
    path: "/detail/:encode",
    name: "detail",
    component: detail,
    meta:{
      keepAlive:false,   //false每次进入都mounted,不复用组件; true直接再使用上次
    }
  },
  {
    path: "/success/:id",
    name: "success",
    component: success,
    meta:{
      keepAlive:false,   //false每次进入都mounted,不复用组件; true直接再使用上次
    }
  },
  {
    path: "/order/:id",
    name: "order",
    component: order,
    meta:{
      keepAlive:false,   //false每次进入都mounted,不复用组件; true直接再使用上次
    }
  },
  {
    path: "/rich/:name",
    name: "rich",
    component: rich,
    meta:{
      keepAlive:false,   //false每次进入都mounted,不复用组件; true直接再使用上次
    }
  },
  {
    path: '*',
    redirect: '/'
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // console.log('---',to);
    return { x: 0, y: 0 };
  },
  
});
export default router;
