const TAG_NAME = "MIXIN_SCROLL.JS";
export default {
    data(){
        return{
            page:0,
            pageSize:4,
            loadingType:'more',
            datas:[],
        }
    },
    mounted(){
        window.addEventListener("scroll", this.onScroll);
        this.tel = this.$route.params.id;
        this.loadMore();
    },
    beforeDestroy(){
        // console.log(TAG_NAME,'beforeDestroy')
        window.removeEventListener("scroll", this.onScroll);
    },
    methods:{
        async loadMore(){
            if(this.loadingType == 'noMore' || this.loadingType == 'loading'){
                return;
            }
            //  console.log(TAG_NAME,'触底了')
            this.loadingType = 'loading';
            this.page++;
            let res =await this.$http('order.lists',{tel:this.tel,page:this.page,listrow:this.pageSize});
            let data= res.data || {};
            const result= data.data || [];
            if(result.length < this.pageSize){
                this.loadingType = 'noMore'
            }else{
                this.loadingType = 'more'
            }
            this.datas= this.datas.concat(result);
        },
        onScroll(){
            //可滚动容器的高度
			let containerEl = this.$el;
            // console.log('containerEl==',containerEl,this)
            let innerHeight = containerEl.offsetHeight + containerEl.offsetTop - 50;
			//屏幕尺寸高度
			let outerHeight = document.documentElement.clientHeight;
			//可滚动容器超出当前窗口显示范围的高度
			 let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			//避免切换时读取到异常高度
			if(scrollTop==0){
				innerHeight=10000
			}
			//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
			// console.log(innerHeight + " " + outerHeight + " " + scrollTop);
			// if(this.isLoad){
			// 	console.log(1)
            if( innerHeight <= outerHeight + scrollTop){
                this.loadMore();
			}
        },
    }
}
