<template>
    <div class="page-addr" ref="addrRef">
        <div class="content">
            <div class="line row">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name b-name"> {{$t('detailTwo.addrName')}}</p>
                    <span class="bitian" >必填</span>
                </div>
                <div class="key long" v-else>
                    <span class="name"> {{$t('detailTwo.addrName')}}</span>
                    <span class="dot" >*</span>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detailTwo.addrNameP')" v-model="buyer_name">
                </div>   
            </div>
            <div class="line row">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name b-name">{{$t('detailTwo.addrMobile')}}</p>
                    <p class="bitian">必填</p>
                </div>
                <div class="key long" v-else>
                    <span class="name"> {{$t('detailTwo.addrMobile')}}</span>
                    <span class="dot" >*</span>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detailTwo.addrMobileP')" v-model="tel">
                </div>   
            </div>
            <div class="line row" v-if="shipping_list.length>0">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name b-name">{{$t('detailTwo.addrShiper')}}</p>
                    <p class="bitian">必填</p>
                </div>
                <div class="key long" v-else>
                    <span class="name"> {{$t('detailTwo.addrShiper')}}</span>
                    <span class="dot" >*</span>
                </div>
                <div class="val row" :class="classObj" >
                    <div class="select-wrap row"  style="margin-bottom:0.10rem"
                      v-for="item in shipping_list" :key="item.id"
                      @click="tapShiper(item)"
                    >
                        <img :src="item.active? require('../../../assets/img/perm/checked.png') :require('../../../assets/img/perm/checkun.png') "  
                             class="ic"
                        >
                        <span class="txt">{{item.title}}</span>
                        <p class="mark" v-if="item.remark">*商品到店簡訊通知</p>
                        <!-- 98折去掉
                        <div class="tips row" v-if="item.discount<1">
                            <span class="zzkicon-sanjiaoleft"></span>
                            <span class="t-val">{{item.discount * 100}}折</span>
                        </div>
                        -->
                    </div>
                </div>   
            </div>
            <div class="line row clearbt" v-if="product_info.zone_level !== 0">
                <div class="key row"  v-if="config.TW === lang || config.CN === lang">
                    <p class="name b-name">{{$t('detailTwo.addrAddr')}}</p>
                    <p class="bitian">必填</p>
                </div>
                <div class="key long" v-else>
                    <span class="name"> {{$t('detailTwo.addrAddr')}}</span>
                    <span class="dot" >*</span>
                </div>
                <div class="val row"  :class="classObj" v-if="lang===LANG_THA">
                     <!-- 仅泰国使用以下二级 detailTwo.addrAddrThaS  ，detailTwo.addrAddrThaC  -->
                    <div class="addr-wrap" v-if="product_info.zone_level>1" @click="toggleAddrPanel($event,'getarea','taparea')">
                        <p class="inpt addr" :class="{active: area.address}"  >{{area.address|| $t('detailTwo.addrAddrThaS')}}</p>
                        <img src="../../../assets/img/perm/selectic.png" class="ic" />
                    </div>
                    <div class="addr-wrap" v-if="product_info.zone_level>0" @click="toggleAddrPanel($event,'getvillage','tapvillage')">
                        <p class="inpt addr" :class="{active: village.address}"  >{{village.address || $t('detailTwo.addrAddrThaC')}}</p>
                        <img src="../../../assets/img/perm/selectic.png" class="ic" />
                    </div>
                    
                </div>
                <div class="val row"  :class="classObj" v-else>
                    <!-- 仅英文马来使用detailTwo.addrAddrS -->
                    <div class="addr-wrap" v-if="product_info.zone_level>2" @click="toggleAddrPanel($event,'getprovince','tapprovince')">
                        <p class="inpt addr" :class="{active: province.address}" >{{province.address || $t('detailTwo.addrAddrS') || $t('detailTwo.addrAddrP')}}</p>
                        <img src="../../../assets/img/perm/selectic.png" class="ic" />
                    </div>
                    <div class="addr-wrap" v-if="product_info.zone_level>1" @click="toggleAddrPanel($event,'getarea','taparea')">
                        <p class="inpt addr" :class="{active: area.address}" >{{area.address||  $t('detailTwo.addrAddrP')}}</p>
                        <img src="../../../assets/img/perm/selectic.png" class="ic" />
                    </div>
                    <div class="addr-wrap" v-if="product_info.zone_level>0" @click="toggleAddrPanel($event,'getvillage','tapvillage')">
                        <p class="inpt addr" :class="{active: village.address}" >{{village.address ||  $t('detailTwo.addrAddrP')}}</p>
                        <img src="../../../assets/img/perm/selectic.png" class="ic" />
                    </div>
                    <!-- <p class="inpt addr" v-if="curShiper.is_cs==1" @click="toggleAddrPanel($event,'getfamilymart','tapfamilymart')" ></p> -->
                    <!--                     
                    <p class="inpt addr mart clamp" @click="toggleAddrPanel($event,'getfamilymart','tapfamilymart')" v-if="curShiper.is_cs==1">
                        {{ familymart.store_name?  ($t('detailTwo.addrStoreName')+':'+familymart.store_name +'.'+$t('detailTwo.addrStoreNum')+':'+familymart.store_num) : $t('detailTwo.addrAddrP')}}
                    </p> 
                    -->
                </div>
            </div>
             <div class="line row" v-if="curShiper.is_cs==1">
                <div class="key row"> </div>
                <div class="val row">
                    <p class="inpt addr clamp"  :class="{active: familymart.store_num}"  
                      style="flex:1;"
                      @click="toggleAddrPanel($event,'getfamilymart','tapfamilymart')" 
                    >
                        {{ familymart.store_name?  ($t('detailTwo.addrStoreName')+':'+familymart.store_name +'.'+$t('detailTwo.addrStoreNum')+':'+familymart.store_num) : $t('detailTwo.addrShopP')}}
                    </p> 
                </div>
            </div>
            <div class="line row">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name b-name">{{$t('detailTwo.addrDetail')}}</p>
                    <p class="bitian">必填</p>
                </div>
                <div class="key long" v-else>
                    <span class="name"> {{$t('detailTwo.addrDetail')}}</span>
                    <span class="dot" >*</span>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detailTwo.addrDetailP')" :disabled="curShiper.is_cs==1" v-model="address">
                </div>   
            </div>
            <div class="line row" v-if="product_info.is_email != 3">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name" :class="{'b-name': product_info.is_email != 1}">{{$t('detailTwo.addrEmail')}}</p>
                    <p class="bitian" v-if="product_info.is_email != 1" >必填</p>
                </div>
                <div class="key long" v-else>
                    <span class="name" > {{$t('detailTwo.addrEmail')}}</span>
                    <span class="dot" v-if="product_info.is_email != 1">*</span>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detailTwo.addrEmailP')" v-model="email">
                </div>   
            </div>
            <div class="line row" v-if="product_info.is_postal != 3">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name" :class="{'b-name': product_info.is_postal != 1}">{{$t('detailTwo.addrCode')}}</p>
                    <p class="bitian" v-if="product_info.is_postal != 1" >必填</p>
                </div>
                <div class="key long" v-else>
                    <span class="name" > {{$t('detailTwo.addrCode')}}</span>
                    <span class="dot" v-if="product_info.is_postal != 1">*</span>
                </div>
                 <!-- :class="{thaMl:lang===LANG_THA}" -->
                <div class="val row" >
                    <input type="text" class="inpt" :placeholder="$t('detailTwo.addrCodeP')" v-model="zipcode">
                </div>   
            </div>
            <div class="line row">
                <div class="key row"  v-if="config.TW === lang || config.CN === lang">
                    <p class="name">{{$t('detailTwo.addrNote')}}</p>
                </div>
                <div class="key long" v-else>
                    <span class="name" > {{$t('detailTwo.addrNote')}}</span>
                </div>
                <!-- :class="{thaMl:lang===LANG_THA}" -->
                <div class="val row " >
                    <input type="text" class="inpt" :placeholder="$t('detailTwo.addrNoteP')" v-model="remark">
                </div>   
            </div>
            <div class="line row">
                <div class="key row" v-if="config.TW === lang || config.CN === lang">
                    <p class="name">{{$t('detailTwo.addrPay')}}</p>
                </div>
                <div class="key long" v-else>
                    <span class="name" > {{$t('detailTwo.addrPay')}}</span>
                </div>
                <div class="val row">
                    <div class="select-wrap row">
                        <!-- <span class="zzkicon-checked"></span> -->
                        <img src="../../../assets/img/perm/checked.png" class="ic" />
                        <span class="txt">{{$t('detailTwo.addrPayV')}}</span>
                    </div>
                </div>   
            </div>
        </div>
        <div class="btn" @click="getAddrData">{{$t('detailTwo.addrBtn')}}</div>

        <div class="addr-panel" v-if="isShowAddrPanel"  >
            <div class="mask"  @click.stop="toggleAddrPanel"></div>
            <div class="content"  :class="{'animated fadeIn':isShowAddrPanel}">
                <div class="addr-line row"  v-for="(item,idx) in addr" :key="idx" @click="tapAddr(item)">
                    <!-- <span class=" key" :class="[item.active ? 'active zzkicon-checked' : 'zzkicon-danxuan']"></span> -->
                    <span class="val clamp" v-if="item.store_name">{{$t('detailTwo.addrStoreName')+':'+item.store_name +'.'+$t('detailTwo.addrStoreNum')+':'+item.store_num}}</span>
                    <span class="val clamp" v-else>{{item.address}}</span>
                </div>
            </div>
        </div>
        <div class="addrpc-panel" v-if="isShowAddrPCPanel">
            <div class="mask"  @click.stop="toggleAddrPanel"></div>
            <div class="content"   :class="{'animated fadeIn':isShowAddrPCPanel}" :style="{top:addrPcTop+'px',left:addrPcLeft+'px'}">
                <div class="addr-line row" v-for="(item,idx) in addr" :key="idx" @click="tapAddr(item)">
                    <span class="val clamp" v-if="item.store_name">{{$t('detailTwo.addrStoreName')+':'+item.store_name +'.'+$t('detailTwo.addrStoreNum')+':'+item.store_num}}</span>
                    <span class="val clamp" v-else>{{item.address}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_ADDR.JS";
  import config from '@/config.js';
//   const previlige={
//       1:''
//   }
  export default {
    data(){
        return{
            LANG_THA:config.THA,
            config,
            isShowAddrPanel:false,
            addr:[], //展示地址列表
            isShowAddrPCPanel:false,
            addrPcTop:0,
            addrPcLeft:0,

            curShiper:{},//当前点击的配送方式is_cs=1商超，详细地址和乡固定
            provinces:[],
            province:{},//选中省名 address兼容familymart
            areas:[],
            area:{},//address选中区
            villages:[],
            village:{},//address选中乡
            nextTapFun:'', //用于列表地址点击时将调用选中函数名
            familymarts:[],//商超列表
            familymart:{},
            address:'',//详细地址

            buyer_name:'',
            tel:'',
            zipcode:'', //邮编
            email:'',   //邮箱
            remark:'',
        }
    },
    props:['shipping_list','product_info'],
    inject:['submit'],
    
     watch:{
        product_info:{
            //content为父组件异步获取，钩子取不到
            handler(v){
                //如果有配送方式，默认选择第一个
                if(this.shipping_list && this.shipping_list.length>0){
                    let shoper = this.shipping_list[0];
                    this.$set(shoper,'active',true);
                    this.curShiper=shoper;
                    this.familymarts=[];//商超列表
                    this.familymart={};
                    this.$bus.$emit("tapShiper",shoper.discount); // "0.98"
                }
                //选择地区，台湾二级，新加坡只填详细地址
                if(this.product_info.zone_level==2){
                    this.province = {address: this.product_info.level_default};
                }
                // console.log(TAG_NAME,'watch', this.province);
            },
            immediate:true
        }
    },
    methods:{

         //点击配送方式
        tapShiper(item){
            this.shipping_list.forEach(shoper => {
                this.$set(shoper,'active',false);
            });
            this.$set(item,'active',true);
            this.curShiper=item;
            this.familymarts=[];//商超列表
            this.familymart={};
            //针对台湾物流折扣价，通知page-spec更改合计金额
            this.$bus.$emit("tapShiper",item.discount); // "0.98"
        },   
        //点击展示地址列表,reqFun请求函数，tapFun下步点击函数 
        async toggleAddrPanel(e,reqFun,tapFun){
            const bodyEl = document.body;
            if(this.$store.state.device == 'mobile'){
                if(!this.isShowAddrPanel){
                    let checkRes = await this[reqFun](); //获取值
                    if(!checkRes){
                        return;
                    }
                    bodyEl.style.overflow = 'hidden';
                    bodyEl.style.height = '100%';
                    this.nextTapFun=tapFun;
                }else{
                    bodyEl.style.overflow = 'auto';
                    bodyEl.style.height = 'auto';
                    this.nextTapFun=''
                }
                this.isShowAddrPanel = !this.isShowAddrPanel;
            }else{
                if(!this.isShowAddrPCPanel){
                    let checkRes = await this[reqFun](); //获取值
                    if(!checkRes){
                        return;
                    }
                    this.addrPcLeft = e.clientX - e.offsetX;
                    this.addrPcTop = e.clientY- e.offsetY + e.target.clientHeight;
                    bodyEl.style.overflow = 'hidden';
                    bodyEl.style.height = '100%';
                    this.nextTapFun=tapFun;
                }else{
                    bodyEl.style.overflow = 'auto';
                    bodyEl.style.height = 'auto';
                    this.nextTapFun=''
                }
                this.isShowAddrPCPanel = !this.isShowAddrPCPanel;
            }
            // this.isShowAddrPCPanel = !this.isShowAddrPCPanel;
        },
        //选中列表一值
        tapAddr(v){
           this[this.nextTapFun](v);
           this.toggleAddrPanel();
        },
        //获省市乡
        async getprovince(){
            //该请求已缓存
            const parentV= {address:-1};
            const curList = 'provinces';
            const url= 'region.getprovince';
            const sendDatad={zone_id:this.product_info.zone_id};
            return await this.getList(parentV,curList,url,sendDatad);
        },
        tapprovince(province){
            this.province = province;
            this.areas=[];
            this.area = '';
            this.villages=[];
            this.village='';
            if(this.curShiper.is_cs==1){
                this.familymarts = [];
                this.familymart={}; 
                this.address='';
            }
        },
        async getarea(){
            const parentV= this.province;
            const curList = 'areas';
            const url= 'region.getarea';
            const sendDatad= {zone_id:this.product_info.zone_id,province:this.province.address};
            return this.getList(parentV,curList,url,sendDatad);
        },
        taparea(area){
            this.area = area;
            this.villages=[];
            this.village='';
            if(this.curShiper.is_cs==1){
                this.familymarts = [];
                this.familymart={}; 
                this.address='';
            }
           
        },
        async getvillage(){
            const parentV= this.area;
            const curList = 'villages';
            const url= 'region.getvillage';
            const sendDatad={zone_id:this.product_info.zone_id,province:this.province.address,area:this.area.address};
            return this.getList(parentV,curList,url,sendDatad);
        },
        async tapvillage(village){
            this.village=village;
            if(this.curShiper.is_cs==1){
                this.familymarts = [];
                this.familymart={}; 
                this.address='';
            }
            // console.log('===',this.lang === this.LANG_THA)
            if(this.lang === this.LANG_THA){
                //tai 回填邮编
                const sendDatad={zone_id:this.product_info.zone_id, village:this.village.address};
                let res =await this.$http('region.getTHAZipCode',sendDatad);
                this.zipcode = res.data || '';
            }
        },
        async getfamilymart(){
            const parentV= this.village;
            const curList = 'familymarts';
            const url= 'Familymart.lists';
            const sendDatad={shipping_id:this.curShiper.id,village:this.village.address,area:this.area.address};
            return this.getList(parentV,curList,url,sendDatad);
        },
        tapfamilymart(familymart){
            this.familymart=familymart;
            this.address = this.familymart.address;
        },
        //封装以上请求
        async getList(parentV,curListName,url,sendDatad){
            if(!parentV || !parentV.address){
                this.$message.warning('请选择上级');
                return false;
            }
            let res =await this.$http(url,sendDatad);
            this[curListName]= res.data || [];
            this[curListName] =this[curListName].map(item=>{
                if(typeof item === 'string'){
                    return {address:item};
                }else{
                    return item;
                }
               
            })
            this.addr=this[curListName];
            return true;
        },

        //地址组件点击提交订单，发父组件处理
        getAddrData(){
           const product_id = this.product_info.id;

           if(this.shipping_list && this.shipping_list.length>0 && !this.curShiper.id){
                this.$message.warning(this.$t('detailTwo.addrShiperP'));
                return false;
           }
           const shipping_id = this.curShiper.id || '';
           

           if(this.curShiper.is_cs==1 && !this.familymart.id){
                this.$message.warning(this.$t('detailTwo.addrAddrP'));
                return false;
           }
           const store_id = this.curShiper.is_cs == 1 ? this.familymart.id : '';//商超地址
  
           if(!this.buyer_name){
                this.$message.warning(this.$t('detailTwo.addrNameP'));
                return false;
           }
           
           if(!this.tel){
                this.$message.warning(this.$t('detailTwo.addrMobileP'));
                return false;
           }

            if(!this.email && this.product_info.is_email == 2){
                this.$message.warning(this.$t('detailTwo.addrEmailP'));
                return false;
           }

            //    if(!this.province.address || !this.area.address || !this.village.address){
            //         this.$message.warning(this.$t('detailTwo.addrAddrP'));
            //         return false;
            //    }
            if(this.product_info.zone_level > 0 && !this.village.address){
                this.$message.warning(this.$t('detailTwo.addrAddrP'));
                return false;
            }
           
            if( !this.address){
                this.$message.warning(this.$t('detailTwo.addrDetailP'));
                return false;
            }


            if(!this.zipcode && this.product_info.is_postal == 2){
                this.$message.warning(this.$t('detailTwo.addrCodeP'));
                return false;
            }

            const addrData={
               product_id,
               shipping_id,
               //combo_id
               store_id,
               buyer_name:this.buyer_name,
               tel:this.tel,
               email:this.email,
               province:this.province.address,
               area:this.area.address,
               village:this.village.address,
               address:this.address,
               zipcode:this.zipcode,
               remark:this.remark,
            //    mobile_brand,
            //    product_data
            }
        //    console.log(addrData)
           this.submit(addrData);
           

        }
    }
}
</script>

<style lang="scss" scoped>
//    *{
//       line-height: 0;
//    }
   .page-addr{
        margin-top: 0.30rem;
        margin-bottom: 0.7rem;
        width: 100%;
        background: #fff;
        overflow: hidden;
        padding:0 0.20rem;
   }
   .content{
       width: 100%;
   }
   .line{
        // margin-bottom: 0.20rem;
       
        align-items: stretch;
        
        &.clearbt{
            margin-bottom: 0;
        }
        .key{
            width: 1.56rem;
            // padding: 0.12rem 0 0.24rem;
            background-color: #E1EDFD;
            margin-right: 0.28rem;
            justify-content: center;
            padding-bottom: 0.24rem;
            .name{
                font-size: 0.28rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
                flex-shrink: 0;
                line-height: 0.38rem;
                // line-height: 0.70rem;
                &.b-name{
                    max-width: 0.60rem;
                }
            }
            
            .bitian{
                flex-shrink: 0;
                padding: 0.04rem 0.05rem;
                margin-left: 0.08rem;
                background-color: #5B0414;
                display: inline-block;

                font-size: 0.24rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
            }
            &.long{
                font-size: 0;
                text-align: center;
                padding: 0.10rem 0;
                .name{
                    font-size: 0.26rem;
                    font-family: PingFang SC;
                    font-weight: bold;
                    color: #333333;
                    flex-shrink: 0;
                    line-height: 0.30rem;
                    // line-height: 0.70rem;
                    // word-wrap: break-word;
                }
               .dot{
                    color: #5B0414;
                    font-size: 0.30rem;
                    font-family: PingFang SC;
                    font-weight: bold;
                    margin-left: 0.02rem;
                } 
            }
        }

        .inpt{
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            width: 100%;
            height: 0.70rem;
            line-height: 0.70rem;
            padding: 0 0.20rem;
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333;
            margin-bottom: 0.24rem;
            &.addr{
                color:#999999;
            }
            &.active{
                color:#333;
            }
            &.mart{
                flex:1;
            }
        }
        .addr-wrap{
            position: relative;
            margin-bottom: 0.20rem;
            margin-right: 0.10rem;
            .addr{
                // width: 1.71rem;
                font-size: 0.24rem;
                color:#999999;
                margin-bottom: 0;
                &.active{
                    color:#333;
                }
                &:last-child{
                    margin-right: 0;
                }
            }     
            .ic{
                position: absolute;
                right: 0.10rem;
                top: 50%;
                transform: translateY(-50%);
                width: 0.30rem;
                height: 0.30rem;
            }
        }
        
        .val{
            // width: 5.34rem;
            flex:1;
            flex-wrap: wrap;

            &.mobile{
                .addr-wrap{
                   width: 100%;
                   margin-right: 0;
                }   
                .select-wrap{
                    width: 100%;
                }
            }
            &.pc{
                .addr-wrap{
                   flex:1;
                } 
                .select-wrap{
                    min-width: 25%;
                }
            }
            
        } 
        .select-wrap{
            width: 2.66rem;
            min-height: 0.60rem;
            cursor: pointer;
            flex-wrap: wrap;
            .mark{
                color:#D52B3C;
                font-size: 0.24rem;
                flex-shrink: 0;
            }
            .ic{
                width: 0.32rem;
                height: 0.32rem;
                margin-right: 0.14rem;
                flex-shrink: 0;
            }
            .txt{
                font-size: 0.26rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                margin-right: 0.08rem;
                flex-shrink: 0;
            }
            .tips{
                height: 0.34rem;
                position: relative;
            }

        }
        // .zzkicon-danxuan{
        //     font-size: 0.28rem;
        //     color: #999999;
        //     margin-right: 0.10rem;
        // }
        // .zzkicon-checked{
        //     color: #FC3538;
        //     font-size: 0.28rem;
        //     margin-right: 0.10rem;
        // }
      
        .zzkicon-sanjiaoleft{
            position: absolute;
            left: -0.12rem;
            top: 50%;
            transform: translateY(-50%);
            color: #FC3538;
            font-size: 0.20rem;
        }
        .t-val{
            // width: 0.70rem;
            height: 0.34rem;
            background: #FC3538;
            border-radius: 0.17rem;
            color:#fff;
            line-height: 0.34rem;
            text-align: center;
            font-size: 0.22rem;
            padding:0  0.08rem;
        }


   }
   .btn{
        margin: 0.50rem auto 0;
        width: 5.10rem;
        height: 0.74rem;
        background: #FC3538;
        line-height: 0.74rem;
        text-align: center;
        
        font-size: 0.34rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #FFFFFF;
   }
   .addr-panel{
       position: fixed;
       left: 0;
       top: 0;
       z-index: 12;
       width: 100%;
       height: 100%;
       .mask{
           position: absolute;
           left: 0;
           top: 0;
           width: 100%;
           height: 100%;
           z-index: 5;
           background: rgba(0,0,0,.4);
       }
       .content{
           position: absolute;
           z-index: 6;
           background: #fff;
           border-radius: 0.20rem;
           width: 80%;
           height: 5.60rem;
           padding: 0 0.30rem;
           left: 50%;
           top: 50%;
           margin-top: -2.80rem;
           margin-left: -40%;
           overflow-y: scroll;
       }
       .addr-line{
           width: 100%;
           border-bottom: 1px solid #eee;
           height: 0.80rem;
           .key{
               font-size: 0.32rem;
               margin-right: 0.20rem;
               color:#333;
               flex-shrink: 0;
               &.active{
                   color:#FC3538;
               }
           }
           .val{
               font-size: 0.28rem;
               color:#333;
           }

       }


   }
   .addrpc-panel{
            position: fixed;
            left: 0;
            top: 0;
            z-index: 12;
            width: 100%;
            height: 100%;
            .mask{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
            }
            .content{
                position: absolute;
                z-index: 6;
                background: #fff;
                overflow-y: scroll;
                width: 171px;
                height: 240px;
                padding: 0 15px;
                border:1px solid #ccc;
            }
            .addr-line{
                height: 40px;
                width: 100%;
                border-bottom: 1px solid #eee;
                .key{
                    color:#333;
                    flex-shrink: 0;
                    font-size: 16px;
                    margin-right: 10px;
                }
                .val{
                    font-size: 14px;
                    color:#333;
                }

            }
        }
    @media (max-width:751px){
        .thaMl{
            margin-left: 10px;
        }
    }

</style>
