<template>
   <div class="page">
     <div class="p-inner">
        <pageHeader />
        <pageTips :tip='tip' />
        <pageProj :info="info" />
        <!-- <pageCode /> -->
     </div>
   </div>
</template>

<script>
  const TAG_NAME = "DETAIL.VUE";
//   import { MessageBox} from 'element-ui';
  import pageHeader from './Header.vue';
  import pageTips from './Tips.vue';
  import pageProj from './Proj.vue';
  import mixinResize from './js/mixinResize';
  import pageCode from './Code.vue';
 
  export default {
    components:{
       pageHeader,
       pageTips,
       pageProj,
       pageCode
    },
    data(){
        return{
           info:{},
           tip:'',
        }
    },
    mixins: [mixinResize],
    created(){
         const order_id = this.$route.params.id;
         this.init(order_id);
         this.getTips();
    },
    mounted(){
    },
    methods:{
         async init(order_id){
            let res =await this.$http('order.info',{order_id});
            this.info = res.data || {};
         }, 
         async getTips(){
            let res =await this.$http('basedata.find',{key:'buysuccess'});
            this.tip = res.data || {};
         }, 

    }
}
</script>

<style lang="scss" scoped>
  .page{
      background: #f1f1f1;
     
  }
   .p-inner{
      min-height: 100vh;
      background: #f8f8f8;
      width: 100%;
      max-width: 750px;
      margin:0 auto;
  }
 
   @media (min-width:751px){
      // 本页只有两种布局，区别首页
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */    color:    #CCCCCC; font-size:12px;}
      
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    #CCCCCC;font-size: 12px; }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */   color:    #CCCCCC;font-size: 12px; }
      
      ::placeholder { color: #999; font-size: 12px; }
   }

</style>
