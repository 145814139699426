import { setLang } from '@/assets/js/cookie.js'
import { mapState } from 'vuex'
import config from '@/config';
// import store from '@/store'

const TAG_NAME = "MIXIN.JS";

var mixin = {
    data: function () {
        return {
        //   lang:config.lang.CN, //简CN，繁TC
          lang:config.CN, //简CN，繁TC
        }
    },
    computed: {
        ...mapState({
          device: state => state.device,
        }),
        classObj() {
          return  this.device === 'mobile' ? 'mobile' : 'pc';
        }
    },
    mounted(){
    },
    watch:{
        '$i18n.locale': {
            handler: function (val){
                // console.log(TAG_NAME,'监听到$i18n.locale变化---',this.$i18n.locale);
                this.lang=val || config.CN;
                setLang(val || config.CN);
                if(this.refreshByLangChge){
                    //因更语言刷新
                    this.refreshByLang();
                }
            },
            immediate: true, 
        },
    },
    methods:{
        navTo(page,type=1){
            // 1push，2replace
            if(type==2){
                this.$router.replace(page);
            }else{
                this.$router.push(page);
            }
        },
        goHome(){
            this.navTo('/',2)
        },
        goBack(){
            this.$router.go(-1)
        },
    }
}
export default mixin;