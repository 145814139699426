<template>
    <div class="page-addr" ref="addrRef">
        <div class="title">
            {{$t('detail.addrTitle')}}
        </div>
        <div class="content">
            <div class="line row">
                <div class="key row">
                    <span class="zzkicon-bitian"></span>
                    <p class="name"> {{$t('detail.addrName')}}</p>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detail.addrNameP')" v-model="buyer_name">
                </div>   
            </div>
            <div class="line row">
                <div class="key row">
                    <span class="zzkicon-bitian"></span>
                    <p class="name">{{$t('detail.addrMobile')}}</p>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detail.addrMobileP')" v-model="tel">
                </div>   
            </div>
            <div class="line row" v-if="shipping_list.length>0">
                <div class="key row">
                    <span class="zzkicon-bitian"></span>
                    <p class="name">{{$t('detail.addrShiper')}}</p>
                </div>
                <div class="val row">
                    <div class="select-wrap row" 
                      v-for="item in shipping_list" :key="item.id"
                      @click="tapShiper(item)"
                    >
                        <span :class="[item.active? 'zzkicon-checked' :'zzkicon-danxuan']"></span>
                        <span class="txt">{{item.title}}</span>
                        <div class="tips row" v-if="item.discount<1">
                            <span class="zzkicon-sanjiaoleft"></span>
                            <span class="t-val">{{item.discount * 100}}折</span>
                        </div>
                    </div>
                </div>   
            </div>
            <div class="line row clearbt" v-if="product_info.zone_level !== 0">
                <div class="key row">
                    <span class="zzkicon-bitian"></span>
                    <p class="name">{{$t('detail.addrAddr')}}</p>
                </div>

                <div class="val row"  :class="classObj" v-if="lang===LANG_THA">
                     <!-- 仅泰国使用以下二级 detail.addrAddrThaS  ，detail.addrAddrThaC  -->
                    <p class="inpt addr" :class="{active: area.address}" v-if="product_info.zone_level>1" @click="toggleAddrPanel($event,'getarea','taparea')">{{area.address|| $t('detail.addrAddrThaS')}}</p>
                    <p class="inpt addr" :class="{active: village.address}" v-if="product_info.zone_level>0" @click="toggleAddrPanel($event,'getvillage','tapvillage')">{{village.address || $t('detail.addrAddrThaC')}}</p>
                </div>
                <div class="val row"  :class="classObj" v-else>
                    <!-- 仅英文马来使用detail.addrAddrS -->
                    <p class="inpt addr" :class="{active: province.address}" v-if="product_info.zone_level>2" @click="toggleAddrPanel($event,'getprovince','tapprovince')">{{province.address || $t('detail.addrAddrS') || $t('detail.addrAddrP')}}</p>
                    <p class="inpt addr" :class="{active: area.address}" v-if="product_info.zone_level>1" @click="toggleAddrPanel($event,'getarea','taparea')">{{area.address||  $t('detail.addrAddrP')}}</p>
                    <p class="inpt addr" :class="{active: village.address}" v-if="product_info.zone_level>0" @click="toggleAddrPanel($event,'getvillage','tapvillage')">{{village.address ||  $t('detail.addrAddrP')}}</p>
                    <!-- <p class="inpt addr" v-if="curShiper.is_cs==1" @click="toggleAddrPanel($event,'getfamilymart','tapfamilymart')" ></p> -->
                    <!--                     
                    <p class="inpt addr mart clamp" @click="toggleAddrPanel($event,'getfamilymart','tapfamilymart')" v-if="curShiper.is_cs==1">
                        {{ familymart.store_name?  ($t('detail.addrStoreName')+':'+familymart.store_name +'.'+$t('detail.addrStoreNum')+':'+familymart.store_num) : $t('detail.addrAddrP')}}
                    </p> 
                    -->
                </div>
            </div>
             <div class="line row clearbt" v-if="curShiper.is_cs==1">
                <div class="key row"> </div>
                <div class="val row">
                    <p class="inpt addr mart clamp" @click="toggleAddrPanel($event,'getfamilymart','tapfamilymart')" >
                        {{ familymart.store_name?  ($t('detail.addrStoreName')+':'+familymart.store_name +'.'+$t('detail.addrStoreNum')+':'+familymart.store_num) : $t('detail.addrShopP')}}
                    </p> 
                </div>
            </div>
            <div class="line row">
                <div class="key row">
                    <span class="zzkicon-bitian"></span>
                    <p class="name">{{$t('detail.addrDetail')}}</p>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detail.addrDetailP')" :disabled="curShiper.is_cs==1" v-model="address">
                </div>   
            </div>
            <div class="line row" v-if="product_info.is_email != 3">
                <div class="key row">
                    <span class="zzkicon-bitian" :class="{ empty: product_info.is_email == 1}"></span>
                    <p class="name">{{$t('detail.addrEmail')}}</p>
                </div>
                <div class="val row">
                    <input type="text" class="inpt" :placeholder="$t('detail.addrEmailP')" v-model="email">
                </div>   
            </div>
            <div class="line row" v-if="product_info.is_postal != 3">
                <div class="key row">
                    <span class="zzkicon-bitian" :class="{ empty: product_info.is_postal == 1}"></span>
                    <p class="name">{{$t('detail.addrCode')}}</p>
                </div>
                <div class="val row"  :class="{thaMl:lang===LANG_THA}">
                    <input type="text" class="inpt" :placeholder="$t('detail.addrCodeP')" v-model="zipcode">
                </div>   
            </div>
            <div class="line row">
                <div class="key row">
                    <span class="zzkicon-bitian empty"></span>
                    <p class="name">{{$t('detail.addrNote')}}</p>
                </div>
                <div class="val row " :class="{thaMl:lang===LANG_THA}">
                    <input type="text" class="inpt" :placeholder="$t('detail.addrNoteP')" v-model="remark">
                </div>   
            </div>
            <div class="line row">
                <div class="key row">
                    <span class="zzkicon-bitian empty"></span>
                    <p class="name">{{$t('detail.addrPay')}}</p>
                </div>
                <div class="val row">
                    <div class="select-wrap row">
                        <span class="zzkicon-checked"></span>
                        <span class="txt">{{$t('detail.addrPayV')}}</span>
                    </div>
                </div>   
            </div>
        </div>
        <div class="btn" @click="getAddrData">{{$t('detail.addrBtn')}}</div>

        <div class="addr-panel" v-if="isShowAddrPanel"  >
            <div class="mask"  @click.stop="toggleAddrPanel"></div>
            <div class="content"  :class="{'animated fadeIn':isShowAddrPanel}">
                <div class="addr-line row"  v-for="(item,idx) in addr" :key="idx" @click="tapAddr(item)">
                    <!-- <span class=" key" :class="[item.active ? 'active zzkicon-checked' : 'zzkicon-danxuan']"></span> -->
                    <span class="val clamp" v-if="item.store_name">{{$t('detail.addrStoreName')+':'+item.store_name +'.'+$t('detail.addrStoreNum')+':'+item.store_num}}</span>
                    <span class="val clamp" v-else>{{item.address}}</span>
                </div>
            </div>
        </div>
        <div class="addrpc-panel" v-if="isShowAddrPCPanel">
            <div class="mask"  @click.stop="toggleAddrPanel"></div>
            <div class="content"   :class="{'animated fadeIn':isShowAddrPCPanel}" :style="{top:addrPcTop+'px',left:addrPcLeft+'px'}">
                <div class="addr-line row" v-for="(item,idx) in addr" :key="idx" @click="tapAddr(item)">
                    <span class="val clamp" v-if="item.store_name">{{$t('detail.addrStoreName')+':'+item.store_name +'.'+$t('detail.addrStoreNum')+':'+item.store_num}}</span>
                    <span class="val clamp" v-else>{{item.address}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_ADDR.JS";
  import config from '@/config.js';
//   const previlige={
//       1:''
//   }
  export default {
    data(){
        return{
            LANG_THA:config.THA,
            isShowAddrPanel:false,
            addr:[], //展示地址列表
            isShowAddrPCPanel:false,
            addrPcTop:0,
            addrPcLeft:0,

            curShiper:{},//当前点击的配送方式is_cs=1商超，详细地址和乡固定
            provinces:[],
            province:{},//选中省名 address兼容familymart
            areas:[],
            area:{},//address选中区
            villages:[],
            village:{},//address选中乡
            nextTapFun:'', //用于列表地址点击时将调用选中函数名
            familymarts:[],//商超列表
            familymart:{},
            address:'',//详细地址

            buyer_name:'',
            tel:'',
            zipcode:'', //邮编
            email:'',   //邮箱
            remark:'',
        }
    },
    props:['shipping_list','product_info'],
    inject:['submit'],
    
     watch:{
        product_info:{
            //content为父组件异步获取，钩子取不到
            handler(v){
                //如果有配送方式，默认选择第一个
                if(this.shipping_list && this.shipping_list.length>0){
                    let shoper = this.shipping_list[0];
                    this.$set(shoper,'active',true);
                    this.curShiper=shoper;
                    this.familymarts=[];//商超列表
                    this.familymart={};
                    this.$bus.$emit("tapShiper",shoper.discount); // "0.98"
                }
                //选择地区，台湾二级，新加坡只填详细地址
                if(this.product_info.zone_level==2){
                    this.province = {address: this.product_info.level_default};
                }
                // console.log(TAG_NAME,'watch', this.province);
            },
            immediate:true
        }
    },
    methods:{

         //点击配送方式
        tapShiper(item){
            this.shipping_list.forEach(shoper => {
                this.$set(shoper,'active',false);
            });
            this.$set(item,'active',true);
            this.curShiper=item;
            this.familymarts=[];//商超列表
            this.familymart={};
            //针对台湾物流折扣价，通知page-spec更改合计金额
            this.$bus.$emit("tapShiper",item.discount); // "0.98"
        },   
        //点击展示地址列表,reqFun请求函数，tapFun下步点击函数 
        async toggleAddrPanel(e,reqFun,tapFun){
            const bodyEl = document.body;
            if(this.$store.state.device == 'mobile'){
                if(!this.isShowAddrPanel){
                    let checkRes = await this[reqFun](); //获取值
                    if(!checkRes){
                        return;
                    }
                    bodyEl.style.overflow = 'hidden';
                    bodyEl.style.height = '100%';
                    this.nextTapFun=tapFun;
                }else{
                    bodyEl.style.overflow = 'auto';
                    bodyEl.style.height = 'auto';
                    this.nextTapFun=''
                }
                this.isShowAddrPanel = !this.isShowAddrPanel;
            }else{
                if(!this.isShowAddrPCPanel){
                    let checkRes = await this[reqFun](); //获取值
                    if(!checkRes){
                        return;
                    }
                    this.addrPcLeft = e.clientX - e.offsetX;
                    this.addrPcTop = e.clientY- e.offsetY + e.target.clientHeight;
                    bodyEl.style.overflow = 'hidden';
                    bodyEl.style.height = '100%';
                    this.nextTapFun=tapFun;
                }else{
                    bodyEl.style.overflow = 'auto';
                    bodyEl.style.height = 'auto';
                    this.nextTapFun=''
                }
                this.isShowAddrPCPanel = !this.isShowAddrPCPanel;
            }
            // this.isShowAddrPCPanel = !this.isShowAddrPCPanel;
        },
        //选中列表一值
        tapAddr(v){
           this[this.nextTapFun](v);
           this.toggleAddrPanel();
        },
        //获省市乡
        async getprovince(){
            //该请求已缓存
            const parentV= {address:-1};
            const curList = 'provinces';
            const url= 'region.getprovince';
            const sendDatad={zone_id:this.product_info.zone_id};
            return await this.getList(parentV,curList,url,sendDatad);
        },
        tapprovince(province){
            this.province = province;
            this.areas=[];
            this.area = '';
            this.villages=[];
            this.village='';
            if(this.curShiper.is_cs==1){
                this.familymarts = [];
                this.familymart={}; 
                this.address='';
            }
        },
        async getarea(){
            const parentV= this.province;
            const curList = 'areas';
            const url= 'region.getarea';
            const sendDatad= {zone_id:this.product_info.zone_id,province:this.province.address};
            return this.getList(parentV,curList,url,sendDatad);
        },
        taparea(area){
            this.area = area;
            this.villages=[];
            this.village='';
            if(this.curShiper.is_cs==1){
                this.familymarts = [];
                this.familymart={}; 
                this.address='';
            }
           
        },
        async getvillage(){
            const parentV= this.area;
            const curList = 'villages';
            const url= 'region.getvillage';
            const sendDatad={zone_id:this.product_info.zone_id,province:this.province.address,area:this.area.address};
            return this.getList(parentV,curList,url,sendDatad);
        },
        async tapvillage(village){
            this.village=village;
            if(this.curShiper.is_cs==1){
                this.familymarts = [];
                this.familymart={}; 
                this.address='';
            }
            console.log('===',this.lang === this.LANG_THA)
            if(this.lang === this.LANG_THA){
                //tai 回填邮编
                const sendDatad={zone_id:this.product_info.zone_id, village:this.village.address};
                let res =await this.$http('region.getTHAZipCode',sendDatad);
                this.zipcode = res.data || '';
            }
        },
        async getfamilymart(){
            const parentV= this.village;
            const curList = 'familymarts';
            const url= 'Familymart.lists';
            const sendDatad={shipping_id:this.curShiper.id,village:this.village.address,area:this.area.address};
            return this.getList(parentV,curList,url,sendDatad);
        },
        tapfamilymart(familymart){
            this.familymart=familymart;
            this.address = this.familymart.address;
        },
        //封装以上请求
        async getList(parentV,curListName,url,sendDatad){
            if(!parentV || !parentV.address){
                this.$message.warning('请选择上级');
                return false;
            }
            let res =await this.$http(url,sendDatad);
            this[curListName]= res.data || [];
            this[curListName] =this[curListName].map(item=>{
                if(typeof item === 'string'){
                    return {address:item};
                }else{
                    return item;
                }
               
            })
            this.addr=this[curListName];
            return true;
        },

        //地址组件点击提交订单，发父组件处理
        getAddrData(){
           const product_id = this.product_info.id;

           if(this.shipping_list && this.shipping_list.length>0 && !this.curShiper.id){
                this.$message.warning(this.$t('detail.addrShiperP'));
                return false;
           }
           const shipping_id = this.curShiper.id || '';
           

           if(this.curShiper.is_cs==1 && !this.familymart.id){
                this.$message.warning(this.$t('detail.addrAddrP'));
                return false;
           }
           const store_id = this.curShiper.is_cs == 1 ? this.familymart.id : '';//商超地址
  
           if(!this.buyer_name){
                this.$message.warning(this.$t('detail.addrNameP'));
                return false;
           }
           
           if(!this.tel){
                this.$message.warning(this.$t('detail.addrMobileP'));
                return false;
           }

            if(!this.email && this.product_info.is_email == 2){
                this.$message.warning(this.$t('detail.addrEmailP'));
                return false;
           }

            //    if(!this.province.address || !this.area.address || !this.village.address){
            //         this.$message.warning(this.$t('detail.addrAddrP'));
            //         return false;
            //    }
            if(this.product_info.zone_level > 0 && !this.village.address){
                this.$message.warning(this.$t('detail.addrAddrP'));
                return false;
            }
           
            if( !this.address){
                this.$message.warning(this.$t('detail.addrDetailP'));
                return false;
            }


            if(!this.zipcode && this.product_info.is_postal == 2){
                this.$message.warning(this.$t('detail.addrCodeP'));
                return false;
            }

            const addrData={
               product_id,
               shipping_id,
               //combo_id
               store_id,
               buyer_name:this.buyer_name,
               tel:this.tel,
               email:this.email,
               province:this.province.address,
               area:this.area.address,
               village:this.village.address,
               address:this.address,
               zipcode:this.zipcode,
               remark:this.remark,
            //    mobile_brand,
            //    product_data
            }
        //    console.log(addrData)
           this.submit(addrData);
           

        }
    }
}
</script>

<style lang="scss" scoped>
//    *{
//       line-height: 0;
//    }
   .page-addr{
        margin-top: 0.20rem;
        width: 100%;
        background: #fff;
        border-radius: 0.20rem;
        overflow: hidden;
        padding: 0.30rem;
        // position: relative;
   }
   .title{
        width: 100%;
        font-size: 0.32rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-bottom: 0.20rem;
   }
   .content{
       width: 100%;
   }
   .line{
        margin-bottom: 0.20rem;
        align-items: flex-start;
        &.clearbt{
            margin-bottom: 0;
        }
        .key{
            min-width: 1.56rem;
            height: 0.70rem;
            .zzkicon-bitian{
                font-size: 0.30rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FC3538;
                // margin-right: 0.04rem;
                &.empty{
                    color:transparent;
                }
            }
            .name{
                font-size: 0.28rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #333333;
                flex-shrink: 0;
            }
        }
        
        .val{
            width: 5.34rem;
            flex-wrap: wrap;

            &.mobile{
                .addr{
                   width: 100%;
                }   
            }
        } 
        .inpt{
            border: 1px solid #CCCCCC;
            border-radius: 5px;
            width: 100%;
            height: 0.70rem;
            line-height: 0.70rem;
            padding: 0 0.20rem;
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333;
            &.mart{
                flex:1;
            }
        }
        .addr{
            width: 1.71rem;
            margin-right: 0.10rem;
            font-size: 0.24rem;
            margin-bottom: 0.20rem;
            color:#999999;
            &.active{
                color:#333;
            }
            &:nth-child(3n){
                margin-right: 0;
            }
        }  
        .select-wrap{
            width: 2.66rem;
            height: 0.70rem;
            cursor: pointer;
        }
        .zzkicon-danxuan{
            font-size: 0.28rem;
            color: #999999;
            margin-right: 0.10rem;
        }
        .zzkicon-checked{
            color: #FC3538;
            font-size: 0.28rem;
            margin-right: 0.10rem;
        }
        .txt{
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-right: 0.08rem;
        }
        .tips{
            height: 0.34rem;
            position: relative;
        }
        .zzkicon-sanjiaoleft{
            position: absolute;
            left: -0.12rem;
            top: 50%;
            transform: translateY(-50%);
            color: #FC3538;
            font-size: 0.20rem;
        }
        .t-val{
            // width: 0.70rem;
            height: 0.34rem;
            background: #FC3538;
            border-radius: 0.17rem;
            color:#fff;
            line-height: 0.34rem;
            text-align: center;
            font-size: 0.22rem;
            padding:0  0.08rem;
        }


   }
   .btn{
        margin-top: 0.20rem;
        width: 100%;
        height: 0.80rem;
        background: #FC3538;
        border-radius: 0.10rem;
        line-height: 0.80rem;
        text-align: center;
        
        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
   }
   .addr-panel{
       position: fixed;
       left: 0;
       top: 0;
       z-index: 12;
       width: 100%;
       height: 100%;
       .mask{
           position: absolute;
           left: 0;
           top: 0;
           width: 100%;
           height: 100%;
           z-index: 5;
           background: rgba(0,0,0,.4);
       }
       .content{
           position: absolute;
           z-index: 6;
           background: #fff;
           border-radius: 0.20rem;
           width: 80%;
           height: 5.60rem;
           padding: 0 0.30rem;
           left: 50%;
           top: 50%;
           margin-top: -2.80rem;
           margin-left: -40%;
           overflow-y: scroll;
       }
       .addr-line{
           width: 100%;
           border-bottom: 1px solid #eee;
           height: 0.80rem;
           .key{
               font-size: 0.32rem;
               margin-right: 0.20rem;
               color:#333;
               flex-shrink: 0;
               &.active{
                   color:#FC3538;
               }
           }
           .val{
               font-size: 0.28rem;
               color:#333;
           }

       }


   }
    @media (max-width:751px){
        .thaMl{
            margin-left: 10px;
        }
    }
   // 1400px  1920px共有部分
   @media (min-width:751px){
        .page-addr{
            margin-top: 10px;
            border-radius: 10px;
            padding: 15px;
        }
        .title{
            font-size: 16px;
            margin-bottom: 10px;
        }
        .line{
            margin-bottom: 10px;
            .key{
                min-width: 156px;
                height: 35px;
                .zzkicon-bitian{
                    font-size: 15px;
                }
                .name{
                    font-size: 14px;
                }
            }
            .val{
                width: 534px;
            } 
            .inpt{
                height: 35px;
                line-height: 35px;
                padding: 0 10px;
                font-size: 13px;
            } 
            .addr{
                width: 171px;
                margin-right: 5px;
                font-size: 12px;
                margin-bottom: 10px;
                &:nth-child(3n){
                    margin-right: 0px;
                }
            }  
            .select-wrap{
                width: 133px;
                height: 35px;
            }
            .zzkicon-danxuan{
                font-size: 14px;
                margin-right: 5px;
            }
            .zzkicon-checked{
                color:#FC3538;
                font-size: 14px;
                margin-right: 5px;
            }
            .txt{
                font-size: 13px;
                margin-right: 6px;
            }
            .tips{
                height: 17px;
            }
            .zzkicon-sanjiaoleft{
                font-size: 10px;
                left: -6px;
            }
            .t-val{
                // width: 35px;
                height: 17px;
                border-radius: 8px;
                line-height: 17px;
                font-size: 11px;
                padding: 0 4px;
            }
        }
        .btn{
            margin-top: 10px;
            height: 40px;
            border-radius: 5px;
            line-height: 40px;
            
            font-size: 15px;
        }
        .addrpc-panel{
            position: fixed;
            left: 0;
            top: 0;
            z-index: 12;
            width: 100%;
            height: 100%;
            .mask{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 5;
            }
            .content{
                position: absolute;
                z-index: 6;
                background: #fff;
                overflow-y: scroll;
                width: 171px;
                height: 240px;
                padding: 0 15px;
                border:1px solid #ccc;
            }
            .addr-line{
                height: 40px;
                width: 100%;
                border-bottom: 1px solid #eee;
                .key{
                    color:#333;
                    flex-shrink: 0;
                    font-size: 16px;
                    margin-right: 10px;
                }
                .val{
                    font-size: 14px;
                    color:#333;
                }

            }
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
