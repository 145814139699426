<template>
    <div class="page-proj">
        <div class="proj row">
            <img :src="info.thumb_image" class="p-img">
            <div class="p-name column">
                <p class="name clamp2">
                    {{info.product_title}}
                </p>
                <p class="set clamp">{{info.combo_title}}</p>
                <div class="price">
                    {{info.currency_symbol_left+info.price_total}}
                </div>
            </div>
        </div>
        <div class="order">
            <div class="o-line row">
                <p class="k">{{$t('success.oTime')}}</p>
                <p class="v">{{$tools.date('Y-m-d H:i',info.createtime)}}</p>
            </div>
            <div class="o-line row">
                <p class="k">{{$t('success.oNo')}}</p>
                <p class="v">{{info.order_no}}</p>
            </div>
            <div class="o-line row">
                <p class="k">{{$t('success.oPay')}}</p>
                <p class="v">{{$t('detail.servicePay')}}</p>
            </div>
            <div class="o-line row">
                <p class="k">{{$t('success.oShip')}}</p>
                <p class="v">{{$t('detail.serviceShip')}}</p>
            </div>
            <div class="o-line row">
                <p class="k">{{$t('success.oTotal')}}</p>
                <p class="v">{{info.currency_symbol_left+info.price_total}}</p>
            </div>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_PROJ.JS";
  export default {
    data(){
        return{

        }
    },
    props:['info'],
    methods:{

    }
}
</script>

<style lang="scss" scoped>
   .page-proj{
        width: 100%;
        padding: 0.50rem 0.30rem 0;
   }
   .proj{
        width: 100%;
        // height: 2.03rem;
        background: #FFFFFF;
        border-radius: 0.10rem;
        padding: 0.30rem;
        position: relative;
        .price{
            color:#FD383B;
            position: absolute;
            right: 0.40rem;
            bottom: 0.30rem;
            font-size: 0.30rem;
        }
   }
   .p-img{
        width:  1.50rem;
        height: 1.50rem;
        border-radius: 0.10rem;
        margin-right: 0.20rem;
        object-fit: cover;
   }
   .p-name{
       justify-content: space-between;
       height: 1.50rem;
   }
   .name{
        margin-top: 0.04rem;
        height: 0.80rem;
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.40rem;
   }
   .set{
        max-width: 3.00rem;
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        margin-bottom: 0.05rem;
        line-height: 0.52rem;
   }
   
   .order{
       margin-top: 0.20rem;
       width: 100%;
       background: #FFFFFF;
       padding: 0.15rem 0.30rem;
       border-radius: 0.10rem;
   }
   .o-line{
       width: 100%;
       height: 0.56rem;
       line-height: 0.56rem;
       justify-content: space-between;
       .k{
            font-size: 0.28rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
       }
       .v{
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
       }
   }

   // 1400px  1920px共有部分
   @media (min-width:751px){
        .page-proj{
                padding: 25px 15px 0;
        }
        .proj{
                border-radius: 5px;
                padding: 15px;
                .price{
                    right: 20px;
                    bottom: 15px;
                    font-size: 15px;
                }
        }
        .p-img{
                width:  52.5px;
                height: 52.5px;
                border-radius: 5px;
                margin-right: 10px;
        }
        .p-name{
            height: 52.5px;
        }
        .name{
                margin-top: 2px;
                height: 40px;
                font-size: 14px;
                line-height: 20px;
        }
        .set{
                max-width: 150px;
                font-size: 13px;
                line-height: 26px;
                margin-bottom: 2px;
        }
        
        .order{
            margin-top: 10px;
            padding: 7px 15px;
            border-radius: 5px;
        }
        .o-line{
            height: 28px;
            line-height: 28px;
            .k{
                    font-size: 14px;
            }
            .v{
                    font-size: 13px;
            }
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
