<template>
    <div class="page-profile">
        <h1 class="title">{{$t('detail.detailTxt')}}</h1>
        <!-- 富文本 -->
        <div class="rich" >
            <div v-html="richTxt || ''"></div>
            <img :src="product_info.size_image" class="size-img" v-if="product_info.size_image">
        </div>



        <!-- 台湾地区购物需知 -->
        <!--         
        <div class="tips-outer">
            <div class="tips-wrap column" v-if="isTw === true">
                <div class="t-one row">
                    <img src="../../../assets/img/perm/d-elephone.png" class="img">
                    <p class="txt">鑒別服務開創者 杜絕一切僞劣商品</p>
                </div>
                <div class="t-two row">
                    <div class="title-wrap column">
                        <p class="mtitle">買家下單</p>
                        <p class="stitle">賣家發貨給大象優選</p>
                    </div>
                    <img src="../../../assets/img/perm/d-dArr.png" class="title-ic">
                    <div class="title-wrap column">
                        <p class="mtitle">平台實物鑒別</p>
                        <p class="stitle">查驗商品質量情況</p>
                    </div>
                    <img src="../../../assets/img/perm/d-dArr.png" class="title-ic">
                    <div class="title-wrap column">
                        <p class="mtitle">鑒別通過</p>
                        <p class="stitle">大象優選發貨到買家</p>
                    </div>
                </div>
                <div class="t-thr column">
                    <div class="line-wrap row">
                        <img src="../../../assets/img/perm/d-chayan.png"  class="img">
                        <div class="info">
                            <p class="mtitle">商品查驗</p>
                            <p class="stitle">安排專人獨立查驗每件商品,確保買家購買到對版無瑕疵商品</p>
                        </div>
                    </div>
                    <div class="line-wrap row">
                        <img src="../../../assets/img/perm/d-jia.png"  class="img">
                        <div class="info">
                            <p class="mtitle">拒絕劣品</p>
                            <p class="stitle">拒絕一切不對版,數量有誤,劣質,過期等商品</p>
                        </div>
                    </div>
                    <div class="line-wrap row">
                        <img src="../../../assets/img/perm/d-zhengpin.png" class="img">
                        <div class="info">
                            <p class="mtitle">買家放心</p>
                            <p class="stitle">必須經過查驗後,大象優選才會發貨給買家,讓買家放心</p>
                        </div>
                    </div>
                </div>
            </div>           
        </div>
 -->

        <!-- 商品名称 -->
        <div class="name">
            {{product_info.title}}
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_PROFILE.JS";
  export default {
    data(){
        return{
           richTxt:'',
        }
    },
    props:['product_info','isTw'],
    watch:{
        product_info:{
            //content为父组件异步获取，钩子取不到
            handler(v){
                this.richTxt = this.replaceDetail(v.content);
            },
            immediate:true
        }
    },
    methods:{
        replaceDetail(details = ''){
                //newContent仅是details替换后内容;
            let newContent = details.replace(/<img[^>]*>/gi, function (match, capture) { //去除三标签
                match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                return match;
            });
            newContent = newContent.replace(/<br[^>]*\/>/gi, '');
            newContent = newContent.replace(/<img/gi, '<img style="width:100%;height:auto;display:block;margin:0 auto;"');
            return newContent;
        },
    }
}
</script>

<style lang="scss" scoped>
   .page-profile{
        width: 100%;
        // background: #fff;
        border-top-left-radius: 0.20rem;
        border-top-right-radius: 0.20rem;
        overflow: hidden;
        // padding: 0 0.30rem;
   }
   .title{
        width: 100%;
        padding: 0 0.30rem;
        height: 0.75rem;
        line-height: 0.75rem;
        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        background: #fff;
   }
   .rich{
       width: 100%;
       padding: 0 0.30rem 0.20rem;
       background: #fff;
       font-size: 0.28rem;
       line-height: 0.36rem;
       .size-img{
           width: 100%;
           height: auto;
       }
   }
   .tips-wrap{
        width: 100%;
        background: rgba(255, 255, 255, 0.95);
        border-radius: 0.20rem;
        margin: 0.20rem auto;
        padding: 0.30rem 0;
        align-items: center;
        .t-one{
            width: 100%;
            justify-content: center;
            .img{
                width: 0.90rem;
                height: 0.90rem;
                margin-right: 0.15rem;
            }
            .txt{
                font-size: 0.38rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #EC0012;
            }
        }
        .t-two{
            width: 100%;
            justify-content: center;
        }
        .title-wrap{
            height: 1.30rem;
            justify-content: center;
            position: relative;
            align-items: center;
            .mtitle{
                font-size: 0.32rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
            }
            .stitle{
                margin-top: 0.23rem;
                font-size: 0.24rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
            }
            &:last-child{
                margin-right: 0;
            }
        }
        .title-ic{
            width: 0.20rem;
            margin: 0 0.08rem;
        }
        .line-wrap{
            // padding-left: 0.20rem;
            // height: 0.78rem;
            margin-bottom: 0.25rem;
            &:last-child{
                margin-bottom: 0;
            }
            .img{
                height: 0.84rem;
                width: 0.84rem;
                margin-right: 0.10rem;
            }
            .mtitle{
                font-size: 0.32rem;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
            }
            .stitle{
                margin-top: 0.12rem;
                font-size: 0.24rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }
   }
   .name{
        width: 100%;
        background: #fff;
        padding: 0.20rem 0.30rem;
        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
        border-radius: 0.20rem;
        margin-top: 0.20rem;
   }
    // 1400px  1920px共有部分
    @media (min-width:751px){
        .page-profile{
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .title{
            padding: 0 15px;
            height: 37px;
            line-height: 37px;
            font-size: 15px;
        }
        .rich{
            font-size: 14px;
            line-height: 18px;
            padding: 0 15px 10px;
        }
        .name{
            padding: 10px 15px;
            font-size: 15px;
            line-height: 24px;
            border-radius: 10px;
            margin-top: 10px;
        }
        .tips-outer{
             background: #fff;
        }
        .tips-wrap{
            border-radius: 10px;
            margin: 10px 0;
            padding: 15px;
            width: 100%;
            align-items: center;
            .t-one{
                // width: auto;
                .img{
                    width: 54px;
                    height: 54px;
                    margin-right: 10px;
                }
                .txt{
                    font-size: 28px;
                }
            }
            .title-wrap{
                height: 90px;
            
                // width: auto;
                .mtitle{
                    font-size: 24px;
                }
                .stitle{
                    margin-top: 11px;
                    font-size: 18px;
                }
            }
            .title-ic{
                width: 20px;
                margin: 0 10px;
            }
            .line-wrap{
                padding-left: 10px;
                // height: 36px;
                margin-bottom: 20px;
                // justify-content: center;
                .img{
                    height: 50px;
                    width: 50px;
                    margin-right: 5px;
                }
                .mtitle{
                    font-size: 24px;
                }
                .stitle{
                    margin-top: 10px;
                    font-size: 18px;
                }
            }
        }
    }
</style>
