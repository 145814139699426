<template>
    <div class="page-list">
        <div class="inner">
            <div class="title">{{$t('home.litTitle')}}</div>
            <div class="list row">
                <div class="item column" v-for='(item,idx) in datas' :key="idx" @click="goDetail(item.encode)">
                    <img :src="item.thumb_image"  class="img">
                    <p class="name clamp2">{{item.title}}</p>
                    <div class="price">
                        <span class="p-v-mark">{{item.symbol_left}}</span>
                        <h1 class="p-v">{{item.price}}</h1>
                        <h2 class="p-k">¥{{item.market_price}}</h2>
                    </div>
                </div>
            </div>
            <Empty v-if="!datas || datas.length==0" />
        </div>
    </div>
</template>

<script>
import Empty from '../../components/Empty.vue';
  const TAG_NAME = "PAGE_LIST.JS";
  export default {
    components:{
      Empty
    },
    data(){
        return{
        }
    },
    props:['datas'],
    mounted(){
    },
    methods:{
        goDetail(encode){
             this.$router.push({ name: 'detail', params: { encode }});
        },

    }
}
</script>

<style lang="scss" scoped>
   .page-list{
      width: 100%;
   }
   .inner{
       width: 100%;
       padding:0 0.30rem;
       margin: 0 auto;
   }
   .title{
        height: 0.89rem;
        line-height: 0.89rem;
        width: 100%;
        font-size: 0.30rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #282828;
   }
   .list{
       width: 100%;
       flex-wrap: wrap;
   }
   .item{
        margin-right: 0.20rem;
        margin-bottom: 0.15rem;
        width: 3.35rem;
        height: 5.09rem;
        background: #FFFFFF;
        border-radius: 0.10rem;
        overflow: hidden;
        cursor: pointer;
        &:nth-child(2n){
            margin-right: 0;
        }
   }
   .img{
       width: 100%;
       height: 3.35rem;
       object-fit:cover;
   }
   .name{
        width: 100%;
        padding: 0 0.20rem;
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.36rem; 
        height: 0.72rem;
        margin-top: 0.06rem;
   }
   .price{
       margin-top: 0.30rem;
       display: flex;
       align-items: flex-end;
       padding-left: 0.20rem;
   }
   .p-v-mark{
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FC3538;
        margin-right: 0.02rem;
   }
   .p-v{
        max-width: 50%;
        font-size: 0.38rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FC3538;
        margin-right: 0.10rem;
        margin-bottom: -2px;
   }
   .p-k{
        max-width: 40%;
        font-size: 0.24rem;
        font-family: PingFang SC;
        font-weight: 400;
        text-decoration: line-through;
        color: #999999;
        margin-bottom: 0.02rem;
   }
   // 1400px  1920px共有部分
   @media (min-width:769px){
        .inner{
            padding:0;
            width: 12.00rem;
        }
        .item{
            &:nth-child(2n){
                margin-right: 0.20rem;
            }
        }
        .item{
            // float: left;
            margin-right: 0.20rem;
            margin-bottom: 0.30rem;
            width: 2.85rem;
            height: 4.50rem;
            &:nth-child(4n){
                margin-right: 0;
            }
        }

        .img{
            height: 3.50rem;
        }
        .name{
            padding: 0 0.10rem;
            font-size: 0.14rem;
            line-height: 0.20rem; 
            height: 0.40rem;
            margin-top: 0.04rem;
        }
        .price{
            margin-top: 0.10rem;
            padding-left: 0.10rem;
        }
        .p-v-mark{
            font-size: 0.20rem;
        }
        .p-v{
            font-size: 0.24rem;
        }
        .p-k{
            font-size: 0.16rem;
            margin-bottom: 0.01rem;
        }
   }   
   // 1200px 定死的话，大小rem不会对应了
//    @media (min-width:1201px){

//         .inner{
//             width: 1200px !important;
//             max-width: 1200px;
//         }
//    }
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
        .title{
            height: 1.17rem;
            line-height: 1.17rem;
        }

   }
   //1920px为准
   @media (min-width:1401px){
        .title{
            height: 1.40rem;
            line-height: 1.40rem;
        }
   }

</style>
