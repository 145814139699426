<template>
    <div class="page-warrant column">
        <span class="zzkicon-anquanbaozhang"></span>
        <p class="txt">{{$t('detail.wTitle')}}<br>{{$t('detail.wTxt1')}}<br>{{$t('detail.wTxt2')}}</p>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  export default {
    data(){
        return{

        }
    },
    methods:{


        // toggleLang(){
        //   this.$i18n.locale= this.lang == 'CN' ? 'TC' : 'CN';
        // },
        // stopMove(){
        //     return;
        // },
    }
}
</script>

<style lang="scss" scoped>
   .page-warrant{
        margin-top: 0.20rem;
        width: 100%;
        background: #fff;
        border-radius: 0.10rem;
        align-items: center;
        padding: 0.20rem 0;

   }
   .zzkicon-anquanbaozhang{
        font-size: 0.65rem;
        color:#1A6EBC;
        margin-bottom: 0.10rem;
   }
   .txt{
        font-size: 0.24rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
        line-height: 0.40rem;
        text-align: center;
   }
   

   // 1400px  1920px共有部分
   @media (min-width:751px){
        .page-warrant{
            margin-top: 10px;
            border-radius: 5px;
            padding: 10px 0;
        }
        .zzkicon-anquanbaozhang{
            font-size: 34px;
            margin-bottom: 5px;
        }
        .txt{
            font-size: 12px;
            line-height: 20px;
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
