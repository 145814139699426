<template>
    <div class="page-slide">
	    <slick
            ref="slick"
            :options="slickOptions"
        >
            <img :src="item" alt="banner"  v-for="item in banners" :key="item">
            <!-- 
            <a href="http://placehold.it/2000x1000" >
              <img src="../../assets/img/temp/banner4.png" alt="">
            </a>
            -->
        </slick>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  import Slick from 'vue-slick';

  export default {
    components: { Slick },
    props:['banners'],
    data(){
        return{
            slickOptions: {
                autoplay:true,
                arrows:false,
                dots:false,
                pauseOnHover:false,
            },
        }
    },
    created(){
    },
    methods:{
        reInit() {
            // 返回首页时调用，刷新
            this.$nextTick(() => {
                this.$refs.slick.reSlick();
            });
        },
    },
}
</script>

<style lang="scss" scoped>
   .page-slide{
       width: 100%;
       height: auto;
   }
   img{
       max-height: 2.74rem;
       width: 100%;
       object-fit:cover;
   }
   // 1400px  1920px共有部分
   @media (min-width:769px){
       
   }   
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
        img{
            max-height: 5.09rem;
        }
   }
   //1920px为准
   @media (min-width:1401px){
        img{
            max-height: 7.00rem;
        }
   }
</style>
