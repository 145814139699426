export const pageHeader = { 
  sTips: '輸入手機號或訂單號搜索訂單',
  tabHome: '首頁',
  tabCat: '分類',
  tabAbout: '關于我們',
}
export const pageFooter = {
  more: '更多信息',
  about:'關于我們',
  item:'服務條款',
  privacy:'隱私政策',
  exchange:'退換政策'
}
export const home = {
  litTitle: '全部商品',
}
export const detail = {
  soldTxt: '售出',
  serviceTime: '15天鑒賞期',
  servicePay: '貨到付款',
  serviceShip: '免運費',
  buyNow: '立即購買',
  detailTxt:'商品詳情',

  combo:'套餐',
  specTitle:'選擇規格',
  specAmountTxt:'合計',

  addrTitle:'填寫信息',
  addrName:'姓名',
  addrNameP:'請輸入收貨人姓名',
  addrMobile:'聯系電話',
  addrMobileP:'請填寫電話號碼',
  addrShiper:'配送方式',
  addrShiperP:'请選擇配送方式',
  addrAddr:'地區',
  addrAddrP:'選擇地區',
  addrAddrS:'選擇地區',
  addrShopP:'選擇門店',
  // addrAddrP:'請填寫城市、地區',
  addrDetail:'詳細地址',
  addrStoreName:'商铺名',
  addrStoreNum:'商鋪號',
  addrDetailP:'請填寫詳細地址',
  addrEmail:'郵     箱',
  addrEmailP:'請填寫郵箱',
  addrCode:'郵     編',
  addrCodeP:'請填寫郵編',
  addrNote:'備     注',
  addrNoteP:'請輸入備注',
  addrPay:'付款方式',
  addrPayV:'貨到付款',
  addrBtn:'確認提交',

  wTitle:'買方擔保',
  wTxt1:'如果妳沒有收到貨物，全額退款',
  wTxt2:'如果您購買的産品和說明不符合全部或部分要求，則全額退款',
}
export const detailTwo = {
  // soldTxt: '售出',
  // serviceTime: '15天鑒賞期',
  // servicePay: '貨到付款',
  // serviceShip: '免運費',
  buyNow: '快速在線訂購',
  // detailTxt:'商品詳情',

  combo:'套餐',
  // specTitle:'選擇規格',
  specAmountTxt:'合計',

  // addrTitle:'填寫信息',
  addrName:'姓名',
  addrNameP:'請輸入收貨人姓名',
  addrMobile:'手機',
  addrMobileP:'請填寫手機號碼',
  addrShiper:'付款方式',
  addrShiperP:'请選擇付款方式',
  addrAddr:'地區',
  addrAddrP:'選擇地區',
  addrAddrS:'選擇地區',
  addrShopP:'選擇門店',
  // addrAddrP:'請填寫城市、地區',
  addrDetail:'詳細地址',
  addrStoreName:'商铺名',
  addrStoreNum:'商鋪號',
  addrDetailP:'請填寫詳細地址',
  addrEmail:'郵     箱',
  addrEmailP:'請填寫郵箱',
  addrCode:'郵     編',
  addrCodeP:'請填寫郵編',
  addrNote:'備     注',
  addrNoteP:'請輸入備注',
  addrPay:'付款方式',
  addrPayV:'貨到付款',
  addrBtn:'確認提交',

  // wTitle:'買方擔保',
  // wTxt1:'如果妳沒有收到貨物，全額退款',
  // wTxt2:'如果您購買的産品和說明不符合全部或部分要求，則全額退款',
  eTitle: '商品评论',
  nTitle: '购物须知',
}
export const success = {
  headerTitle:'購買成功',
  sucTitle:'訂單成功',
  sucTipsT:'恭喜您！您的訂單提交成功，我們將盡快交貨。感謝您的支持！小貼士：支持貨到付款+免費送貨+15天鑒賞期。如果您在收到商品後有任何問題，請與我們的在線服務聯系。我們會盡快回複您！祝您購物愉快！',
  sucTipsB:'請記住訂單號，以便以後再檢查訂單。',
  oTime:'日期',
  oNo:'訂單號',
  oPay:'付款方式',
  oShip:'運費',
  oTotal:'共計',
  cTitle:'通過二維碼添加 LINE 售後客服',
  cTips:'請在LINE應用中打開好友選項，點擊右上角的“添加好友”按鈕，選擇“二維碼”後進行掃描。'
}
export const order = {
  headerTitle:'我的訂單',
  oNo:'訂單號',
  oTime:'購買日期',
  oName:'姓名',
  oMobile:'聯系電話',
  oShiper:'配送方式',
  oAddr:'詳細地址',
  oEmail:'郵箱',
  oNote:'備注',
  oPay:'付款方式',
}
