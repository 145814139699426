const TAG_NAME = "MIXIN_SCROLL.JS";
export default {
    data(){
        return{
           isShowFixBottom:true,
           timer:'',
           specRef:'',
           profileRef:'',
           headerRef:'',
        }
    },
  mounted() {
    window.addEventListener("scroll",this.showbtn,true);
    this.specRef = this.$refs.specRef;
    this.profileRef = this.$refs.profileRef;
    this.headerRef = this.$refs.headerRef;

  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.showbtn); 
    if(this.timer){
        clearInterval(this.timer);
        this.timer = null;
    }
  },
  
  methods: {
    // 显示回到顶部按钮
    showbtn(){
        //滚动高度，目标高度 不包headerRef fixed遮挡部分
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

        let bodyHeight =  document.documentElement.offsetHeight || document.body.offsetHeight;
        bodyHeight -= this.headerRef.$el.offsetHeight;

        let targetTop = this.specRef.$el.offsetTop;
        targetTop -= this.headerRef.$el.offsetHeight;

        // console.log("scrollTop + bodyHeight,targetTop",this.headerRef.$el.offsetHeight,scrollTop + bodyHeight-this.headerRef.$el.offsetHeight,scrollTop,bodyHeight,targetTop)
        if(scrollTop + bodyHeight > targetTop){
            this.isShowFixBottom = false;
        }else{
            this.isShowFixBottom = true;
        }
    },
    backtop(){
        // window.scroll(0,0);
        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        this.timer = setInterval(()=>{
            // console.log(TAG_NAME,'定时中...')
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            let ispeed = Math.floor(-scrollTop / 3); //必须floor, ceil大会有0情况
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop + ispeed;
            if(scrollTop === 0){
                clearInterval(this.timer);
                this.timer = null;
            }
        },30)
    },
    goToBuy(){
        if(this.timer){
            clearInterval(this.timer);
            this.timer = null;
        }
        this.timer = setInterval(()=>{
            // console.log('定时---');

            let targetTop = this.specRef.$el.offsetTop;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            //document.documentElement.scrollTop为滚过pageHeader去内容
            //scrollTop += 容易过
            targetTop -= this.headerRef.$el.offsetHeight  

            let distance = Math.abs(targetTop -  scrollTop);
            // console.log("scrollTop2 targetTop",targetTop,scrollTop,distance)
            let ispeed = Math.ceil(distance / 2);  //必须ceil，floor小会有0情况。
            
            if(scrollTop >= targetTop){
                clearInterval(this.timer);
                this.timer=null;
                return;
            }
            document.documentElement.scrollTop = document.body.scrollTop = scrollTop + ispeed;

        },30);
    },
  }
}
