<template>
    <div class="page-notice">
        <div class="title">{{$t('detailTwo.nTitle')}}</div>
        <div class="rich" >
            <div v-html="richTxt || ''"></div>
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_HEADER.JS";
  export default {
    data(){
        return{
            richTxt:'',
        }
    },
    // props:['product_info'],
    // watch:{
    //     product_info:{
    //         //content为父组件异步获取，钩子取不到
    //         handler(v){
    //             this.richTxt = this.replaceDetail(v.content);
    //         },
    //         immediate:true
    //     }
    // },
    created(){
        this.init();
    },
    methods:{
        async init(){
            let res =await this.$http('basedata.find',{key:'buyerread'});
            // this.title= res.data.title || '';
            this.richTxt = this.replaceDetail(res.data.content || '') ;
        },
        replaceDetail(details = ''){
            //newContent仅是details替换后内容;
            let newContent = details.replace(/<img[^>]*>/gi, function (match, capture) { //去除三标签
                match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
                match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
                match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
                return match;
            });
            newContent = newContent.replace(/<br[^>]*\/>/gi, '');
            newContent = newContent.replace(/<img/gi, '<img style="width:100%;height:auto;display:block;margin:0 auto;"');
            return newContent;
        },
    }
}
</script>

<style lang="scss" scoped>
    .page-notice{
        width: 100%;
        background: #fff;
        .title{
            width: 100%;
            height: 0.94rem;
            background: #253684;
            line-height: 0.94rem;
            text-align: center;
            font-size: 0.34rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
        }
        .rich{
            width: 100%;
            padding: 0 0.20rem;
            font-size: 0.30rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 0.40rem;
            text-align: justify;
        }

        // .tips{
        //     width: 100%;
        //     padding: 0 0.30rem;
        //     margin-top: 0.45rem;
        
        //     .t-tips{
        //         color:#F30C0C;
        //     }
        // }
    }
</style>
