import store from '@/store'

const { body } = document
const MOBILE_WIDTH = 768 // 750 refer to Bootstrap's responsive design
const BOOK_WIDTH = 1400; // 1400
// const PC_WIDTH = 1921;   // 1920

const TAG_NAME = "MIXIN_RESIZE.JS";
export default {
  mounted(){
    // if(this.$i18n && this.$i18n.locale){
    //     this.lang=this.$i18n.locale;
    // }
    this.initDevice();
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  methods: {
      /**
       * 
      */
      setFontSize(device) {
        var docEl = document.documentElement;
        var winWidth = docEl.clientWidth;
        var designSize = device == 'mobile' ? 750 : (device == 'book' ? 1400 : 1920);
        document.documentElement.style.fontSize = (winWidth / designSize) * 100 + "px";
        // console.log(TAG_NAME,'setFontSize winWidth',winWidth,document.documentElement.style.fontSize);
      },
      /**
       * 判断是否手机端
      */
      $_getDevice() {
          const rect = body.getBoundingClientRect()
          if(rect.width - 1 < MOBILE_WIDTH){
            return 'mobile'
          }else if(rect.width - 1 < BOOK_WIDTH){
            return 'book'
          }else {
            return 'pc'
          }
      },
      $_resizeHandler() {
        if (!document.hidden) {
          this.initDevice();
        }
      },
      initDevice(){
          const device = this.$_getDevice();
          store.dispatch('toggleDevice', device)
          // console.log(TAG_NAME,'监听到尺寸initDevice device',device);
          this.setFontSize(device);
      },
  }
}
