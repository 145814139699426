// import {post} from './http.js'
// import { setToken,setUsername,setPassword,getUsername } from './auth';
  
function inAction(fn, that, timeout = 2000){
	if(that['in' + fn]){
		return true
	}
	that['in' + fn] = true;
	setTimeout(()=>{
		that['in' + fn] = false;
	}, timeout)
	return false;
}	
function date(format, timeStamp){
	timeStamp=timeStamp * 1000;
	let date = new Date(timeStamp),
		Y = date.getFullYear(),
		m = date.getMonth() + 1,
		d = date.getDate(),
		H = date.getHours(),
		i = date.getMinutes(),
		s = date.getSeconds();
	
	m = m < 10 ? '0' + m : m;
	d = d < 10 ? '0' + d : d;
	H = H < 10 ? '0' + H : H;
	i = i < 10 ? '0' + i : i;
	s = s < 10 ? '0' + s : s;

	return format.replace(/[YmdHis]/g, key=>{
		return {Y,m,d,H,i,s}[key];
	});
}
function formatDate(format, dateV){
	let date = new Date(dateV),
	Y = date.getFullYear(),
	m = date.getMonth() + 1,
	d = date.getDate();
	// H = date.getHours(),
	// i = date.getMinutes(),
	// s = date.getSeconds();
	
	m = m < 10 ? '0' + m : m;
	d = d < 10 ? '0' + d : d;
	// H = H < 10 ? '0' + H : H;
	// i = i < 10 ? '0' + i : i;
	// s = s < 10 ? '0' + s : s;

	return format.replace(/[Ymd]/g, key=>{
		return {Y,m,d}[key];
	});
}

//处理动态ico
function change_icon(link) {
	let $favicon = document.querySelector('link[rel="icon"]');
	if ($favicon !== null) {
	  //   $favicon.parentNode.removeChild();
	  $favicon.href = link;
	} else {
	$favicon = document.createElement("link");
	$favicon.rel = "icon";
	$favicon.href = link;
	document.head.appendChild($favicon);
	}
}

export default {
	inAction,
	date,
	formatDate,
	change_icon
}