export const pageHeader = {
    sTips: 'Enter your phone number or order number to search for orders',
    tabHome: 'front page',
    tabCat: 'Classification',
    tabAbout: 'about Us',
}
export const pageFooter = {
    more: 'More',
    about:'about Us',
    item:'Terms of Service',
    privacy:'Privacy Policy',
    exchange:'Return Policy'
}
export const home = {
    litTitle: 'all products',
}

export const detail = {
    soldTxt: 'ขายแล้ว',

    serviceTime: 'คืนเงิน/สินค้า ภายใน 15 วัน', //15天鉴赏期
    servicePay: 'ของแท้ 100%',
    serviceShip: 'ส่งฟรีทั่วไทย',

    buyNow:  'ซื้อสินค้า',//'ซื้อเลย',
    detailTxt:'แนะนำผลิตภัณฑ์',
   
    combo:'บรรจุภัณฑ์',
    specTitle:'ข้อมูลจำเพาะ',
    specAmountTxt:'ยอดเงิน:',
 
    addrTitle:'กรอกข้อมูล',

    addrName: 'ชื่อ-นามสกุล', //'ชื่อ', 姓名
    addrNameP:'ชื่อ-นามสกุล', //'โปรดป้อนชื่อที่ถูกต้อง',
    addrMobile:'หมายเลขโทรศัพท',//'เบอร์โทร', 电话
    addrMobileP: 'หมายเลขโทรศัพท', //'โปรดป้อนหมายเลขโทรศัพท์',
    addrShiper:  'วิธีชำระเงิน',
    addrShiperP: 'งินสดเมื่อจัดส่ง',

    addrAddr: 'จังหวัด, เขต/อำเภอ',//'ประเทศ',
    // 仅泰国配置以下两项
    addrAddrThaS:'จังหวัด', //省  
    addrAddrThaC:'เขต/อำเภอ',//市区,

    addrShopP:'选择门店', //只有台湾有
    // addrAddrP:'请填写城市、地区',
    //แขวง/ตำบ（这是选择市区）      บ้านเขลที่ （这是选择地区）
    addrDetail: 'รายละเอียดที่อยู่', //'ที่อยู่', 详细地址
    addrStoreName:'商铺名',
    addrStoreNum:'商铺号',
    addrDetailP:'รายละเอียดที่อยู่', //' โปรดป้อนที่อยู่จัดส่งอย่างละเอียดเช่น',
    addrEmail: 'อีเมล',
    addrEmailP:'กรอกอีเมล์ทั่วไป',
    addrCode: 'รหัสไปรษณีย', //'รหัสไปรษณีย์',邮     编
    addrCodeP: 'รหัสไปรษณีย', //'โปรดป้อนรหัสไปรษณีย์ที่ถูกต้อง เพื่อที่จะไม่ให้ทางขนส่งถจัดส่งได้',

    addrNote:'หมายเหตุ',//'คำแนะนำพิเศษ',备注
    addrNoteP:'ฝากข้อความถึงผู้ขายหรือบริษัทขนส่ง',//'โปรดใส่เนื้อหาข้อความของคุณ',


    addrPay:'วิธีการชำระเงิน',  //付款方式
    addrPayV:'เก็บเงินปลายทาง',

    addrBtn: 'สั่งสินค้า',//'ซื้อสินค้า',

    wTitle:'การคุ้มครองผู้ซื้อ',
    wTxt1:'ถ้าคุณไม่ได้รับสินค้าคืนเงินเต็มจำนวน',
    wTxt2:'ถ้าสินค้าที่คุณซื้อไม่ตรงกับรายละเอียดคืนเงินเต็มหรือบางส่วน',

}
export const detailTwo = {
    // soldTxt: '售出',
    // serviceTime: '15天鑒賞期',
    // servicePay: '貨到付款',
    // serviceShip: '免運費',
    buyNow: 'สั่งซื้อออนไลน์ด่วน',
    // detailTxt:'商品詳情',
  
    combo:'บรรจุภัณฑ์',
    // specTitle:'選擇規格',
    specAmountTxt:'ยอดเงิน:',


    // addrTitle:'กรอกข้อมูล',
    addrName: 'ชื่อ-นามสกุล', //'ชื่อ', 姓名
    addrNameP:'ชื่อ-นามสกุล', //'โปรดป้อนชื่อที่ถูกต้อง',
    addrMobile:'หมายเลขโทรศัพท',//'เบอร์โทร', 电话
    addrMobileP: 'หมายเลขโทรศัพท', //'โปรดป้อนหมายเลขโทรศัพท์',
    addrShiper:  'วิธีชำระเงิน',
    addrShiperP: 'งินสดเมื่อจัดส่ง',
    addrAddr: 'จังหวัด, เขต/อำเภอ',//'ประเทศ',
    // 仅泰国配置以下两项
    addrAddrThaS:'จังหวัด', //省  
    addrAddrThaC:'เขต/อำเภอ',//市区,
    addrShopP:'选择门店', //只有台湾有
  

    // addrAddrP:'請填寫城市、地區',
    addrDetail: 'รายละเอียดที่อยู่', //'ที่อยู่', 详细地址
    addrStoreName:'商铺名',
    addrStoreNum:'商铺号',
    addrDetailP:'รายละเอียดที่อยู่', //' โปรดป้อนที่อยู่จัดส่งอย่างละเอียดเช่น',
    addrEmail: 'อีเมล',
    addrEmailP:'กรอกอีเมล์ทั่วไป',
    addrCode: 'รหัสไปรษณีย', //'รหัสไปรษณีย์',邮     编
    addrCodeP: 'รหัสไปรษณีย', //'โปรดป้อนรหัสไปรษณีย์ที่ถูกต้อง เพื่อที่จะไม่ให้ทางขนส่งถจัดส่งได้',
    addrNote:'หมายเหตุ',//'คำแนะนำพิเศษ',备注
    addrNoteP:'ฝากข้อความถึงผู้ขายหรือบริษัทขนส่ง',//'โปรดใส่เนื้อหาข้อความของคุณ',
    addrPay:'วิธีการชำระเงิน',  //付款方式
    addrPayV:'เก็บเงินปลายทาง',
    addrBtn: 'สั่งสินค้า',//'ซื้อสินค้า',
  
    // wTitle:'買方擔保',
    // wTxt1:'如果妳沒有收到貨物，全額退款',
    // wTxt2:'如果您購買的産品和說明不符合全部或部分要求，則全額退款',
    eTitle: 'รีวิวสินค้า',
    nTitle: 'เรื่องที่ควรรู้ในการซื้อ',
  }

export const success = {
    headerTitle:'สำเร็จ',
    sucTitle:'สำเร็จ',
    sucTipsT:' congratulations! Your order has been submitted successfully and we will deliver it as soon as possible. thank you for your support! Tips: Support cash on delivery + free shipping + 15 days appreciation period. If you have any questions after receiving the item, please contact our online service. We will get back to you as soon as possible! Happy shopping!',
    sucTipsB:'กรุณาใส่ชื่อและเบอร์มือถือของท่าน พร้อมใส่เลขที่สั่งซื้ออยู่ในอีเมลด้วย ขอบคุณค่ะ',
    oTime:'เวลาสั่งซื้อ',
    oNo:'เลขที่ใบสั่งซื้อ',
    oPay:'วิธีชำระเงิน',
    oShip:'ค่าขนส่ง',
    oTotal:' ยอดเงิน',
    cTitle:'Add LINE customer service via QR code',
    cTips:'Please open the friend option in the LINE app, click the "Add friend" button in the upper right corner, select "QR code" and scan it'
}
export const order = {
    headerTitle:'My Order',
    oNo:'เลขที่ใบสั่งซื้อ',
    oTime:'เวลาสั่งซื้อ',
    oName:'ชื่อ',
    oMobile:'เบอร์โทร',
    oShiper:'วิธีชำระเงิน',
    oAddr:'ประเทศ',
    oEmail:'อีเมล',
    oNote:'ฝากข้อความ',
    oPay:'วิธีชำระเงิน',
}








