<template>
    <div class="loading-icon center" v-show="loadingType == 'loading'" @mousewheel.prevent>
     <!-- <i class="zzkicon icon-jiazai"></i> -->
	   <img src="../assets/img/perm/loading.png" class="icon-jiazai" />
    </div>
</template>

<script>
export default {

  name: "Loading",
  props:{
    loadingType:String,  //'loading'
  },
  data() {
    return {
    };
  },
  methods: {
  },

};
</script>
<style lang="scss" scoped>
  .loading-icon{
      position: fixed;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      z-index: 1000;
      background: rgba(0,0,0,.4);
      
      .icon-jiazai{
          // color: #609AFB;
          // font-size: 6rem;
          width: 0.10rem;
          height: 0.10rem;
          // font-weight: bold;
          -webkit-animation: clockwise 1.5s linear infinite;
          animation: clockwise 1.5s linear infinite;
      }
  } 
  @-webkit-keyframes clockwise {
      0% { -webkit-transform: rotate(0deg) }
      100% { -webkit-transform: rotate(360deg) }
  }
  @keyframes clockwise {
      0% { transform: rotate(0deg) }
      100% { transform: rotate(360deg) }
  } 

</style>
