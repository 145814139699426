<template>
    <div class="page-header">          
        <div class="f-top">
            <div class="f-top-inner">
                <!-- <img src="../../../assets/img/perm/d-ftop-tai.png" class="ftop"   /> -->
                <img src="../../../assets/img/perm/d-ftop.jpg" class="ftop" v-if="config.TW === lang"  />
                <img src="../../../assets/img/perm/d-ftop-cn.jpg" class="ftop" v-else-if="config.CN === lang"  />
                <img src="../../../assets/img/perm/d-ftop-tai.jpg" class="ftop" v-else-if="config.THA === lang"  />
                <img src="../../../assets/img/perm/d-ftop-en.jpg" class="ftop" v-else-if="config.EN === lang"  />
            </div>
        </div>
    </div>
</template>

<script>
  import config from '@/config.js';
  const TAG_NAME = "PAGE_HEADER.JS";
  export default {
    data(){
        return{
            // IS_TW:false,
            config,
        }
    },
    props:[],
    mounted(){
        // if(this.lang === config.TW){
        //     this.IS_TW=TRUE;
        // }
    },
    methods:{
        // toggleLang(){
        //   this.$i18n.locale= this.lang == 'CN' ? 'TC' : 'CN';
        // },
        // stopMove(){
        //     return;
        // },
    }
}
</script>

<style lang="scss" scoped>
   .f-top{
     position: relative;
     top: 0;
     left: 0;
     width: 7.5rem;
     height: 1.51rem;
    //  width: 6.9rem;
    //  height: 1.39rem;
     background-color: transparent;
     z-index: 2;
     &-inner{
        position: fixed;
        top: 0;
        left: 50%;
        width: 7.5rem;
        height: 1.51rem;
        // width: 6.9rem;
        // height: 1.39rem;
        background: transparent;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-50%);
     }
     .ftop{
        width: 7.5rem;
        height: 1.51rem;
        // width: 6.9rem;
        // height: 1.39rem;
        object-fit: cover;
     }
   }
   @media (min-width:751px){
        .f-top{
            width: 750px;
            height: 150px;
            &-inner{
                width: 750px;
                height: 150px;
            }
             .ftop{
                width: 750px;
                height: 150px;
                object-fit: cover;
            }
        }

        // .f-top{
        //     &-inner{
        //         width: 690px;
        //         height: 139px;
        //     }
        //      .ftop{
        //         width: 690px;
        //         height: 139px;
        //         object-fit: cover;
        //     }
        // }
   }

</style>
