<template>
    <div class="page-proj">
        <div class="proj-wrap" v-for="(item,idx) in datas" :key="idx">
            <div class="proj row">
                <img :src="item.thumb_image" class="p-img">
                <div class="p-name column">
                    <p class="name clamp2">
                        {{item.product_title}}
                    </p>
                    <p class="set clamp">{{item.combo_title}}</p>
                    <div class="price">
                        {{item.currency_symbol_left + item.price_total}}
                    </div>
                </div>
            </div>
            <div class="order">
                <div class="o-line row">
                    <p class="k">{{$t('order.oNo')}}</p>
                    <p class="v">{{item.order_no}}</p>
                </div>
                <div class="o-line row">
                    <p class="k">{{$t('order.oTime')}}</p>
                    <p class="v">{{$tools.date('Y-m-d H:i',item.createtime)}}</p>
                </div>
                <div class="o-line row">
                    <p class="k">{{$t('order.oName')}}</p>
                    <p class="v">{{item.buyer_name}}</p>
                </div>
                 <div class="o-line row">
                    <p class="k">{{$t('order.oMobile')}}</p>
                    <p class="v">{{item.tel}}</p>
                </div>
                <div class="o-line row" v-if="item.shipping_name">
                    <p class="k">{{$t('order.oShiper')}}</p>
                    <p class="v">{{item.shipping_name}}</p>
                </div>
                 <div class="o-line row">
                    <p class="k">{{$t('order.oAddr')}}</p>
                    <p class="v">{{item.province+item.city+item.area+' '+item.address}}</p>
                </div>
                 <div class="o-line row" v-if="item.email">
                    <p class="k">{{$t('order.oEmail')}}</p>
                    <p class="v">{{item.email}}</p>
                </div>
                <div class="o-line row" v-if="item.remark">
                    <p class="k">{{$t('order.oNote')}}</p>
                    <p class="v">{{item.remark}}</p>
                </div>
                <div class="o-line row">
                    <p class="k">{{$t('order.oPay')}}</p>
                    <p class="v">货到付款</p>
                </div>
            </div>            
        </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_PROJ.JS";
  export default {
       data(){
           return{

           }
       },
       props:['datas'],
       methods:{
           
       }
  }
</script>

<style lang="scss" scoped>
   .page-proj{
        width: 100%;
   }
   .proj-wrap{
        padding: 0.30rem;
        background: #FFFFFF;
        margin-bottom: 0.20rem;
        border-radius: 0.20rem;
   }
   .proj{
        width: 100%;
   }
   .p-img{
        width:  2.00rem;
        height: 2.00rem;
        border-radius: 0.10rem;
        margin-right: 0.20rem;
        object-fit: cover;
   }
   .p-name{
       height: 2.00rem;
   }
   .name{
        margin-top: 0.04rem;
        max-height: 0.80rem;
        font-size: 0.28rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.40rem;
   }
   .set{
        margin-top: 0.20rem;
        max-width: 4.00rem;
        font-size: 0.26rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 0.32rem;
   }
    .price{
        margin-top: 0.20rem;
        color:#FD383B;
        font-size: 0.30rem;
    }
   .order{
       margin-top: 0.30rem;
       width: 100%;
   }
   .o-line{
       width: 100%;
       align-items: flex-start;
       .k{
            font-size: 0.28rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            width: 1.50rem;
            height: 0.56rem;
            line-height: 0.56rem;
            flex-shrink: 0;
       }
       .v{
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 0.56rem;
       }
   }

   // 1400px  1920px共有部分
   @media (min-width:751px){
        .proj-wrap{
                padding: 15px;
                margin-bottom: 10px;
                border-radius: 10px;
        }
        .p-img{
                width:  100px;
                height: 100px;
                border-radius: 5px;
                margin-right: 10px;
        }
        .p-name{
            height: 100px;
        }
        .name{
                margin-top: 2px;
                max-height: 40px;
                font-size: 14px;
                line-height: 20px;
        }
        .set{
                margin-top: 10px;
                max-width: 200px;
                font-size: 13px;
                line-height: 16px;
        }
            .price{
                margin-top: 10px;
                font-size: 15px;
            }
        .order{
            margin-top: 15px;
        }
        .o-line{
            .k{
                    font-size: 14px;
                    width: 75px;
                    height: 28px;
                    line-height: 28px;
            }
            .v{
                    font-size: 13px;
                    line-height: 28px;
            }
        }
   } 
   // 1400px为准
   @media (min-width:769px) and (max-width: 1400px){
      
   }
   //1920px为准
   @media (min-width:1401px){
       
   }
</style>
