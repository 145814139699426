<template>
  <div id="app">
    <!-- <loading :loadingType="isLoading"/> -->
	
    <keep-alive>
	   <router-view v-if=" $route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="! $route.meta.keepAlive"/>
	
  </div>
</template>
<script>
import loading from './components/Loading';
import { mapState} from 'vuex';
 import config from '@/config';
export default {
  components: {
    loading,
  },
  data(){
    return{
    }
  },
  
 
  created(){
    //会话内刷新
    this.getBaseInfo();
  },
  computed:{
    ...mapState(['isLoading'])
  },
  methods:{
    async getBaseInfo(){
      let res =await this.$http('baseconfig.website');
      this.baseInfo = res.data || {};
      this.$i18n.locale =  config.lang.includes(res.data.lang_code)? res.data.lang_code : config.CN ;
      // 只统计详情页
      // fbq('init', this.baseInfo.fb_px);
      // fbq('track', 'PageView');

    },
  }
}
</script>
<style lang="scss">
@import './assets/css/icon.css';
@import './assets/css/reset.css';
@import './assets/css/animate.css';
#app {
  font-family:Source Han Sans CN, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  height:100vh;
}
//提示信息
.el-message__icon{
  font-size: 0.28rem;
}
.el-message__content {
    font-size: 0.28rem;
}

</style>
