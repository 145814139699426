<template>
    <div class="page-spec">
        <div class="buy-wrap center">
            <!-- @click="goToBuy" -->
            <div class="b-btn" >{{$t('detailTwo.buyNow')}}</div>
        </div>
        <div class="content">
            <!-- 套餐 -->
            <div v-if="combo.length>0">
                <div class="key dot">
                    {{$t('detailTwo.combo')}}
                </div>
                <!-- 父套餐名 -->
                <div class="combo-wrap" >
                    <div class="val">
                        <div 
                        class="combo-item row" :class="{active:item.active}" 
                        v-for="item in combo" :key="item.id" @click="tapCombo(item)"
                        >
                           <img :src=" item.active ? require('../../../assets/img/perm/checked.png') : require('../../../assets/img/perm/checkun.png')" class="ic">
                           <span class="txt">{{item.title}}</span>
                        </div>
                    </div>    
                </div>
                <!-- 选父套餐后，属性 -->
                <div class="tap-combo" v-for="(cItem,cIdx) in chosenCombo.child" :key="cIdx">
                    <div class="key dot" v-if="chosenCombo.child.length>1">
                        {{cItem.title}}
                    </div>
                    <!-- 属性 -->
                    <div class="attr-wrap" v-for="(gItem,gIdx) in cItem.attr_group" :key="gIdx">
                        <div class="title-wrap row">
                            <span class="k">{{`${gItem.group_name}：`}}</span>
                            <span class="v" >{{gItem.activeAttrName}}</span>
                        </div>
                        <div class="val row">
                            <div :class="[item.image ? 'img-item':'txt-item',{active:item.active}]"  
                                v-for="item in gItem.child" :key="item.id" @click="tapComboAttr(cItem.id,gItem,item)"
                            >
                                <img :src="item.image" class="img" v-if="item.image">
                                <p class="txt clamp" v-if="item.image">{{item.attr_name}}</p>
                                <span class="txt clamp" v-if="!item.image">{{item.attr_name}}</span>
                            </div>
                        </div>  
                    </div>                
                </div>
            </div>
            <!-- 非套餐 -->
            <div v-else>
                <div class="attr-wrap" v-for="(gItem,gIdx) in attr_group" :key="gIdx">
                    <div class="title-wrap row">
                        <span class="k">{{gItem.group_name}}</span>
                    </div>
                    <div class="val row">
                        <div :class="[item.image ? 'img-item':'txt-item',{active:item.active}]"  
                            v-for="item in gItem.child" :key="item.id" @click="tapSingleAttr(gItem,item)"
                        >
                            <img :src="item.image" class="img" v-if="item.image">
                            <p class="txt clamp" v-if="item.image">{{item.attr_name}}</p>
                            <span v-if="!item.image">{{item.attr_name}}</span>
                        </div>
                    </div>  
                </div>
            </div>
        </div>
        <!-- 
        <div class="amount-detail" v-if="isTw === true">
            <div class="title">價格明細</div>
            <div class="amount" >
                <p class="k">原價</p>
                <p class="mark">{{product_info.symbol_left}}</p>
                <p class="v">{{ chosenCombo.price || product_info.price}}</p>
            </div>     
            <div class="amount">
                <p class="k">折扣</p>
                <p class="unit">-</p>
                <p class="mark">{{product_info.symbol_left}}</p>
                <p class="v">{{discountMoney}}</p>
            </div>
            <div class="amount">
                <p class="k">{{$t('detail.specAmountTxt')}}</p>
                <p class="mark">{{product_info.symbol_left}}</p>
                <p class="v">{{realMoney}}</p>
            </div>            
        </div>
        -->
        <div class="amount-detail">
            <div class="amount">
                <span class="k">{{$t('detail.specAmountTxt')}}：</span>
                <span class="mark">{{product_info.symbol_left}}</span>
                <span class="v">{{ chosenCombo.price || product_info.price}}</span>
            </div>   
        </div>  
        

    </div>
</template>

<script>
  const TAG_NAME = "PAGE_SPEC.JS";
  export default {
    data(){
        return{
            // isCombo:false, //是否套餐
            chosenCombo:{},//选中的父套餐,子：买二，送一。用于循环子,附带属性
            product_data:[
               {
                    combo_goods_id:'',//子套餐ID 买二，送一。多组数据;
                    group_arr:[
                        {
                          gId:'',
                          attr_id:'', //一组对应一值
                        }
                    ],
                    //attr_ids:[],  //属性IDs传后端
               }
            ],
            discountMoney:'', //折扣价
            realMoney:'', //合计价
            singleProData:[],//无套餐情况，{gId:'',attr_id:'', //一组对应一值}
        }
    },
    props:['attr_group','combo','product_info'],
    watch:{
        combo:{
           handler(nCombo,oCombo){
               if(nCombo && nCombo.length>0 && nCombo !== oCombo ){
                    //选中父套餐，初入第一个,只触发一次
                    this.$set(nCombo[0],'active',true);
                    this.chosenCombo = nCombo[0];
                    this.initComboAttr(this.chosenCombo);
                }else{
                    //无套餐情况，只触发一次
                    this.initSingleAttr();
                }
           },
           immediate:true,
        },
    },
    mounted(){
        //  this.$emit("tapShiper",item.discount);
        this.$bus.$on("tapShiper",e=>{
            // console.log('66--',e);
            let originPrice = parseFloat(this.chosenCombo.price || this.product_info.price);
            let discountMoney = this.getDiscount(originPrice,e);
            this.discountMoney = discountMoney.toFixed(2)
            this.realMoney = originPrice - discountMoney;
        });
    },
    methods:{
        //获折扣价
        getDiscount(price,discount=1){
            return parseFloat(1-discount) * parseFloat(price)
        },
        //操作父套餐================================================
        tapCombo(item){
          //选中父套餐
          this.combo.forEach(cItem => {
              cItem.active= false;
          });
          this.$set(item,'active',true);
          this.chosenCombo = item;
          this.initComboAttr(this.chosenCombo);
        },
        initComboAttr(chosenCombo){
            //chosenCombo确定后，初始化属性
            this.product_data = [];  //重置
            chosenCombo.child.forEach((comboS,comboIdx)=>{
                //最底层套餐：买二comboS
                //comboS.attr_group用于页面active,避免买二，送一同时使用一个attr_group，
                //深拷贝，每个comboS使用不同attr_group
                var tempAttrs = this.attr_group.map(attrs=> {
                    if(attrs.child && attrs.child.length>0){
                        let cAttrs= attrs.child.map(item=>{
                              return {...item}
                        })
                        return Object.assign({},attrs,{child:cAttrs});
                    }else{
                        return {}
                    }

                });
                this.$set(comboS,'attr_group',tempAttrs);
                let group_arr=[]; 
                comboS.attr_group.forEach(attrs=>{
                    //attr最底层属性名 attrs父属性(如颜色)
                    let attr =  attrs.child[0]; 
                    this.$set(attr,'active',true)
                    this.$set(attrs,'activeAttrName',attr.attr_name)  //前端展示选中属性
                    group_arr.push({
                        gId:attrs.id,
                        attr_id:attr.id,
                        attr_name: attr.attr_name,
                    })
                })
                let tempObj={
                    combo_goods_id: comboS.id,
                    group_arr
                }
                this.product_data.push(tempObj);
            })
        },
        //操作套餐属性================================================
        tapComboAttr(cId,gItems,item){
          //点击属性，cId子套餐ID买二,属性组gItems，选中属性值
          if(item.active){
              return;
          }
          gItems.child.forEach(gItem => {
              this.$set(gItem,'active',false);
          });
          this.$set(item,'active',true);
          this.$set(gItems,'activeAttrName',item.attr_name)  //前端展示选中属性

          let chosedPro =  this.product_data.find(pItem=>pItem.combo_goods_id == cId); //初始一定有值
          chosedPro.group_arr.forEach(gArr=>{
              //gItems为item父级
              if(gArr.gId == gItems.id){
                  gArr.attr_id = item.id;
                  gArr.attr_name = item.attr_name
              }
          })
        },
       //操作无套餐,初始化属性================================================
        initSingleAttr(){
            let group_arr=[]; 
            this.attr_group.forEach(attrs=>{
                //attr最底层属性名 attrs父属性(如颜色)
                let attr =  attrs.child[0]; 
                this.$set(attr,'active',true)
                group_arr.push({
                    gId:attrs.id,
                    attr_id:attr.id,
                })

            })
            this.singleProData=group_arr;
        },
        //操作无套餐属性================================================
        tapSingleAttr(gItems,item){
          //点击属性，cId子套餐ID买二,属性组gItems，选中属性值
          if(item.active){
              return;
          }
          gItems.child.forEach(gItem => {
              this.$set(gItem,'active',false);
          });
          this.$set(item,'active',true);
          this.singleProData.forEach(gArr=>{
              //gItems为item父级
              if(gArr.gId == gItems.id){
                  gArr.attr_id = item.id;
              }
          })
        },
        //提交获取数据==============================================
        getProData(){
            if(this.combo && this.combo.length>0){
                this.product_data.forEach(comboS=>{
                    comboS.attr_ids=[];
                    comboS.group_arr.forEach(gAttr=>{
                        comboS.attr_ids.push(gAttr.attr_id);
                    })
                }) 
                return {
                    product_data:JSON.stringify(this.product_data),
                    combo_id:this.chosenCombo.id,
                    price:this.chosenCombo.price,
                }; 
            }else{
                let singleProData= this.singleProData.map(item=>item.attr_id)
                return {
                    product_data:JSON.stringify(singleProData),
                    price:this.product_info.price,
                }; 
            }
        },
    }
}
</script>

<style lang="scss" scoped>
//    *{
//       line-height: 0;
//    }
   .page-spec{
        width: 100%;
        background: #fff;
        overflow: hidden;
   }
   .buy-wrap{
       .b-btn{
            // width: 6.89rem;
            width: 100%;
            height: 0.94rem;
            background: #253684;
            line-height: 0.94rem;
            text-align:center;
            color:#fff;
            font-size: 0.34rem;
            font-family: PingFang SC;
            font-weight: 500;
       }
   }


   .content{
       width: 100%;
       padding:0.39rem 0.20rem 0;
   }
   .key{
        margin-bottom: 0.30rem;
        font-size: 0.34rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        position: relative;
        font-weight: bold;
        &.dot{
            padding-left: 0.10rem;
            border-left: 0.06rem solid #FC3538;
        }
   }
   .val{
       width: 100%;
       flex-wrap: wrap;
   }
   
   .combo-wrap{
      margin-bottom: 0.40rem;
      .combo-item{
            width: 100%;
            // height: 0.83rem;
            border: 0.04rem solid #999999;
            padding: 0.20rem 0.10rem;
            margin-bottom: 0.20rem;
            display: flex;
            align-items: flex-start;
            &.active{
                    border: 0.04rem solid #D21B2D;
                    .txt{
                        color: #D21B2D;
                    }
            }
            .ic{
                width: 0.32rem;
                height: 0.32rem;
                margin-right: 0.10rem;
                margin-top: 0.04rem;
            }
            .txt{
                    font-size: 0.30rem;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    max-width: 90%;
                    line-height: 0.42rem;
                    word-break: break-all;
                    text-align: justify;
            }
        }
   }
   .attr-wrap{
      margin-bottom: 0.15rem;
   }
   .title-wrap{
      margin-bottom: 0.20rem;
      .k{
        font-size: 0.30rem;
        font-family: PingFang SC;
        color: #333333;
      }
      .v{
        font-size: 0.30rem;
        font-family: PingFang SC;
        color: #333333;
        font-weight: bold;
      }
   }
   .img-item{
        width: 2.20rem;
        background: #FFFFFF;
        border: 1px solid #EEEEEE;
        border-radius: 0.05rem;
        overflow: hidden;
        margin-bottom: 0.20rem;
        margin-right: 0.15rem;
        line-height: 0;
        &.active{
            border: 0.04rem solid #FC3538;
            .txt{
                color: #D21B2D;
            }
        }
        // &:nth-child(3n){
        //     margin-right: 0;
        // }
        .img{
            width: 100%;
            height: 1.80rem;
            object-fit: cover;
        }
        .txt{
            // width: 2.20rem;
            height: 0.60rem;
            line-height: 0.60rem;
            text-align:center;
            font-size: 0.28rem;
            font-family: PingFang SC;
            color: #333333;
            padding: 0 0.1rem;
        }
   }
   .txt-item{
    //    width: 2.20rem;
    //    height: 0.97rem;
    //    padding: 0 0.10rem;
    // line-height: 0.90rem;
       padding: 0 0.40rem;
       line-height: 0.60rem;

       
       color:#333;

       font-size: 0.32rem;
       margin-bottom: 0.20rem;
       margin-right: 0.15rem;
       border: 0.04rem solid #EEEEEE;
       text-align: center;
    //    &:nth-child(3n){
    //         margin-right: 0;
    //    }
       &.active{
            color:#D52B3C;
            border-color: #D52B3C;
       }
   }
   .amount-detail{
       margin-top: 0.20rem;
       padding:0 0.20rem;
   }
   .amount{
      height: 0.94rem;
      width: 100%;
      background-color: #D7D7D7;
      display: flex;
      align-items: center;
      justify-content: center;
      .k,.mark,.v{
            color: #333333;
            font-size: 0.34rem;
            font-family: PingFang SC;
            font-weight: bold;
            // display: inline-block;
      }
   }

   // 1400px  1920px共有部分
//    @media (min-width:751px){
//         .page-spec{
//             margin-top: 10px;
//             border-radius: 10px;
//             padding: 15px;
//         }
//         .title{
//             font-size: 16px;
//         }
//         .content{
//             padding-top: 10px;
//         }
//         .key{
//             margin-top: 5px;
//             font-size: 14px;
//             &.dot{
//                 padding-left: 5px;
//                 border-left: 2px solid #FC3538;
//             }
//             &.bitian{
//                 padding-left: 8px;
//                 .zzkicon-bitian{
//                     font-size: 14px;
//                     left: -6px;
//                 }
//             }
//         }
//         .val{
//             margin-top: 10px;
//         }
//         .txt-item{
//             height: 30px;
//             padding: 0 15px;
//             line-height: 30px;
//             font-size: 13px;
//             margin-bottom: 10px;
//             margin-right: 15px;
//             border-radius: 3px;
//             cursor: pointer;
//         }
//         .img-item{
//             width: 110px;
//             border-radius: 2px;
//             margin-bottom: 10px;
//             margin-right: 7px;
//             cursor: pointer;
//             &:nth-child(3n){
//                 margin-right: 7px;
//             }           
//             .img{
//                 height: 90px;
//             }

//             .txt{
//                 width: 110px;
//                 height: 30px;
//                 line-height: 30px;
//                 font-size: 13px;
//             }
//         }
//         .amount-detail{
//             margin-top: 10px;
//         }
//         .amount{
//             margin-top: 8px;
//             height: 20px;
//             .k{
//                 font-size: 14px;
//                 margin-right: 12px;
//             }
//             .unit{
//                 font-size: 15px;
//             }
//             .mark{
//                 font-size: 14px;
//                 margin-right: 8px;
//             }
//             .v{
//                 font-size: 20px;
//             }
//         }
//    } 
//    // 1400px为准
//    @media (min-width:769px) and (max-width: 1400px){
      
//    }
//    //1920px为准
//    @media (min-width:1401px){
       
//    }
</style>
