<template>
   <div class="page column">
	   <!-- <pageHeader :canGoAbout=" pName !== 'about'" /> -->
     <pageHeader  :title='title'/>
	   <pageContent :content="content" />
     <!-- <pageFooter /> -->
       
   </div>
</template>

<script>
//   import { MessageBox} from 'element-ui';
  import pageHeader from './Header.vue';
  // import pageFooter from '@/components/HomeFooter.vue';
  import pageContent from './Content.vue';
  import mixinResize from './js/mixinResize'
  import config from '@/config.js';
  export default {
    components:{
       pageHeader,
       pageContent,
      //  pageFooter,
    },
    data(){
        return{
            pName:'', //about
            title:'', //文字
            content:'',
        }
    },
    mixins: [mixinResize],
    created(){
      //  console.log(TAG_NAME,'---',this.$route.params.name)
      this.pName = this.$route.params.name || '';
      // switch(this.pName){
      //   case 'about':
      //     this.title = this.$t('pageFooter.about');
      //   case 'item':
      //     this.title = this.$t('pageFooter.item');
      //   case 'privacy':
      //     this.title = this.$t('pageFooter.privacy');
      //   case 'exchange':
      //     this.title = this.$t('pageFooter.exchange'); 
      // }
       this.init();
    },
    mounted(){
     
    },
    methods:{
      async init(){
        let res =await this.$http('basedata.find',{key:config[this.pName]});
        this.title= res.data.title || '';
        this.content = res.data.content || '';
      }
    }
}
</script>

<style lang="scss" scoped>
   .page{
      background: #f8f8f8;
      min-height: 100vh;
      // padding-bottom: 2.80rem;
      position: relative;
   }
  //  @media (min-width:769px){
  //      .page{
  //           padding-bottom: 2.00rem;
  //       }
  //  }
</style>
