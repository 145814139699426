export default {
    lang:['cn','tw','tha','en'], //语言，语言和国家两个字段，国家汉字，zone_id:2台湾
    CN:'cn',
    TW:'tw',
    THA:'tha',
    EN:'en',
    // baseURL:'http://cod.lanmaonet.com/', //测试后端地址 --开发在vue.config.js内配置
    baseURL:'https://myhuixiang.com/',   //正式后端地址 测试也不用改
    //关于我们,服务条款,隐私协议,退换货政策
    about:'aboutus',
    item:'server',
    privacy:'userinfo',
    exchange:'refund',
}