<template>
   <div class="page">
      <component :is="layoutkey" :info="info"></component>
   </div>
</template>

<script>
  import PageOne from './detailOne'
  import PageTwo from './detailTwo'
  export default {
    components:{
      PageOne,
      PageTwo,
    },
    data(){
      return{
         info:{},
         product_info:{},
         layoutkey: PageOne,
      }
    },
    created(){
      const encode = this.$route.params.encode;
      this.init(encode);
    },
    mounted(){
      
    },
    methods:{
        async init(encode){
            let res =await this.$http('product.info',{encode});
            this.info =res.data || {};
            const {product_info={}} = this.info;
            this.product_info = product_info;
            this.layoutkey = product_info.layoutkey === 'layout_old' ?  PageOne : PageTwo;
        }
      }
   }
</script>

<style lang="scss" scoped>
  .page{
      background: #f1f1f1;
      min-height: 100vh;
  }
  .p-inner{
      background: #f8f8f8;
      min-height: 100vh;
      padding-bottom: 2.80rem;
      position: relative;
      width: 100%;
      max-width: 750px;
      margin:0 auto;
  }
   @media (min-width:751px){
      .p-inner{
          padding-bottom: 140px;
          
      }
      // 本页只有两种布局，区别首页
      ::-webkit-input-placeholder { /* WebKit, Blink, Edge */    color:    #CCCCCC; font-size:12px;}
      
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */   color:    #CCCCCC;font-size: 12px; }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */   color:    #CCCCCC;font-size: 12px; }
      
      ::placeholder { color: #999; font-size: 12px; }
   }

</style>
