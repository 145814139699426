<template>
    <div class="page-info">
            <div class="price-wrap row">
                <div class="price">
                    <span class="mark">{{product_info.symbol_left}}</span>
                    <span class="price-v">{{product_info.price}}</span>
                    <span class="price-k">{{product_info.symbol_left}}{{product_info.market_price}}</span>
                </div>
                <div class="sold">{{$t('detail.soldTxt')}}{{product_info.sales}}</div>
            </div>
            <div class="name-wrap">
                {{product_info.title}}
            </div>
            <div class="ensure-wrap row" v-if="isTw">
                <img src="../../../assets/img/perm/elphant-logo.png" alt="logo" class="e-img">
                <div class="e-name-wrap row">
                    <p class="name">先鑒別後發貨</p>
                    <p class="dot">·</p>
                    <p class="name">杜絕僞劣品</p>
                    <p class="dot">·</p>
                    <p class="name">假一賠三</p>
                </div>
            </div>
            <div class="service-wrap row">
                <div class="s-item row">
                    <img src="../../../assets/img/perm/s-time.png" class="s-img">
                    <span class="s-txt">{{$t('detail.serviceTime')}}</span>
                </div>
                <div class="s-item row">
                    <img src="../../../assets/img/perm/s-pay.png" class="s-img">
                    <span class="s-txt">{{$t('detail.servicePay')}}</span>
                </div>
                <div class="s-item row">
                    <img src="../../../assets/img/perm/s-ship.png" class="s-img">
                    <span class="s-txt">{{$t('detail.serviceShip')}}</span>
                </div>
            </div>
            <div class="buy-wrap center">
                <div class="b-btn" @click="goToBuy">{{$t('detail.buyNow')}}</div>
            </div>
    </div>
</template>

<script>
  const TAG_NAME = "PAGE_INFO.JS";
  export default {
    data(){
        return{

        }
    },
    inject:['goToBuy'],
    props:['product_info','isTw'],
    methods:{
       
    }
}
</script>

<style lang="scss" scoped>
   .page-info{
        width: 100%;
   }
   .price-wrap{
       width: 100%;
       height: 1.00rem;
       padding-left: 0.30rem;
       background: #FC3538;
        .price{
            display: flex;
            align-items: flex-end;
        }
        .mark{
            margin-bottom: 0.02rem;
            color:#fff;
            font-size: 0.38rem;
        }
        .price-v{
            color:#fff;
            font-size: 0.60rem;
        }
        .price-k{
            margin-bottom: 0.06rem;
            margin-left: 0.18rem;
            font-size: 0.30rem;
            font-family: PingFang SC;
            font-weight: 400;
            text-decoration: line-through;
            color: #FFFFFF;
        }
        .sold{
            flex-shrink: 0;
            margin-left: auto;
            width: 1.93rem;
            height: 1.00rem;
            line-height: 1.00rem;
            text-align: center;
            color:#fff;
            font-size: 0.30rem;
            background: #C51417;
        }       
   }
   .name-wrap{
        background: #fff;
        padding: 0.20rem 0.30rem;
        font-size: 0.30rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 0.48rem;
   }
   .ensure-wrap{
       background: #fff;
       border-top: 1px solid #eee;
       padding:0 0.40rem 0 0.30rem;
       height: 0.86rem;
        .e-img{
           width: 0.50rem;
           height: 0.50rem;
           margin-right: 0.10rem;
        }
        .e-name-wrap{
            flex: 1;
            justify-content: space-around;
            .name{
                font-size: 0.26rem;
                font-family: PingFang SC;
                // font-weight: 500;
                color: #999; 
            }
            .dot{
                 font-size: 0.32rem;
                font-family: PingFang SC;
                color:#999;
            }

        }
   }
   .service-wrap{
       height: 0.86rem;
       width: 100%;
       background: #fff;
       border-top: 1px solid #eee;
       border-bottom-left-radius: 0.20rem;
       border-bottom-right-radius: 0.20rem;
       .s-item{
          justify-content: center;
          width: 33.3%;
          flex-shrink: 0;
       }
       .s-img{
           width: 0.26rem;
           height: 0.26rem;
           margin-right: 0.07rem;
       }
       .s-txt{
            font-size: 0.26rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #999999;
       }
   }
   .buy-wrap{
       width: 100%;
       height: 1.23rem;
       .b-btn{
            // width: 6.89rem;
            width: 96%;
            height: 0.80rem;
            background: #FC3538;
            border-radius: 0.10rem;
            line-height: 0.80rem;
            text-align:center;
            color:#fff;
            font-size: 0.30rem;
            font-family: PingFang SC;
            font-weight: 500;
       }
   }
   // 1400px  1920px共有部分
   @media (min-width:751px){

            .ensure-wrap{
                padding:0 20px 0 15px;
                height: 43px;
                .e-img{
                    width: 30px;
                    height: 30px;
                    margin-right: 5px;
                }
                .e-name-wrap{
                    .name{
                        font-size: 13px;
                    }
                    .dot{
                        font-size: 16px;
                    }

                }
            }



            .price-wrap{
                height: 50px;
                padding-left: 15px;
                    .mark{
                        margin-bottom: 1px;
                        font-size: 19px;
                    }
                    .price-v{
                        font-size: 30px;
                    }
                    .price-k{
                        margin-bottom: 3px;
                        margin-left: 9px;
                        font-size: 15px;
                    }
                    .sold{
                        width: 96px;
                        height: 50px;
                        line-height: 50px;
                        font-size: 15px;
                    }       
            }
            .name-wrap{
                padding:10px 15px;
                font-size: 15px;
                line-height: 24px;
                min-height: 42px;
            }
            .service-wrap{
                height: 42px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                .s-img{
                    width: 13px;
                    height: 13px;
                    margin-right: 3px;
                }
                .s-txt{
                    font-size: 13px;
                }
            }
            .buy-wrap{
                height: 61px;
                .b-btn{
                       width: 96%;
                        height: 40px;
                        border-radius: 5px;
                        line-height: 40px;
                        font-size: 15px;
                }
            }
   }
</style>
