import Cookies from 'js-cookie'

const langKey = 'lang'
export function getLang() {
  return Cookies.get(langKey)
}
export function setLang(lang) {
  return Cookies.set(langKey, lang)
}
export function removeLang() {
  return Cookies.remove(langKey);
}

// import {Encrypt,Decrypt} from '@/assets/js/secret'
// const usernameKey='C004F498645223DCDE26D74708AE1552'
// const passwordKey='2E26C63DE132066249922C604680C7FA'

// const TokenKey = 'Admin-Token'
// export function getToken() {
//   return Cookies.get(TokenKey)
// }
// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }
// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

// export function getUsername() {
//   return Decrypt(Cookies.get(usernameKey))
// }
// export function setUsername(username) {
//   return Cookies.set(usernameKey, Encrypt(username))
// }
// export function removeUsername() {
//   return Cookies.remove(usernameKey)
// }


